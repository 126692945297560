<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    :infinite-scroll-distance="perPage"
  >
    <div class="content-transference">
      <div class="top p-4">
        <div :class="isMobile ? 'content-one' : 'content-one-multiple'">
          <div :class="isMobile ? 'ml-auto mr-auto mb-2' : 'ml-1 mr-1'">
            <WhiteLabel :class="isMobile ? 'ml-1 mr-1' : ''">
              <a
                v-if="!getBalance.balanceBlocked"
                data-toggle="modal"
                :data-target="'#' + modalPayment"
                :class="
                  isMobile
                    ? ''
                    : 'pointer btn btn-orange btn-pill align-self btn-transference'
                "
              >
                <i :class="`icon-small icon-plus ${isMobile ? '' : 'mr-2'}`" />
                Pagar
              </a>
            </WhiteLabel>
          </div>
          <div :class="isMobile ? 'ml-auto mr-auto mb-2' : 'ml-1 mr-1'">
            <WhiteLabel :class="isMobile ? 'ml-1 mr-1' : ''">
              <a
                v-if="!getBalance.balanceBlocked"
                data-toggle="modal"
                :data-target="'#' + modalTransferBase"
                :class="
                  isMobile
                    ? ''
                    : 'pointer btn btn-orange btn-pill align-self btn-transference'
                "
              >
                <i
                  v-if="isMobile"
                  :class="`icon-small icon-plus mr-${isMobile ? '1' : '2'}`"
                />
                <em v-else class="icon transfer white" />Transferir
              </a>
            </WhiteLabel>
          </div>
          <div :class="isMobile ? 'ml-auto mr-auto mb-2' : 'ml-1 mr-1'">
            <WhiteLabel :class="isMobile ? 'ml-1 mr-1' : ''">
              <a
                v-if="!getBalance.balanceBlocked"
                data-toggle="modal"
                :data-target="'#' + modalBilling"
                :class="
                  isMobile
                    ? ''
                    : 'pointer btn btn-orange btn-pill align-self btn-transference'
                "
              >
                <i
                  v-if="isMobile"
                  :class="`icon-small icon-plus mr-${isMobile ? '1' : '2'}`"
                />
                <i
                  v-else
                  :class="`icon-small fa fa-dollar mr-${isMobile ? '1' : '2'}`"
                />Cobrar
              </a>
            </WhiteLabel>
          </div>
          <div :class="isMobile ? 'ml-auto mr-auto mb-2' : 'ml-auto mr-2'">
            <WhiteLabel v-if="!isMobile">
              <a
                data-toggle="modal"
                :data-target="'#' + modalCustomerService"
                class="btn btn-outline cursor-pointer"
              >
                <i class="icon-small icon-info mr-2" />Atendimento
              </a>
            </WhiteLabel>

            <a
              v-else
              data-toggle="modal"
              :data-target="'#' + modalCustomerService"
              class="ml-1 mr-1"
            >
              <i class="icon-small icon-info mr-1" />Atendimento
            </a>
          </div>
        </div>
      </div>
    </div>
    <CreateTransferModal
      :id="modalTransferBase"
      :balance="getBalance"
      @payment-created="updateSearch()"
    />
    <PayPixOrderModal
      :id="modalPayment"
      :balance="getBalance"
      @payment-created="updateSearch()"
    />
    <SelectPixKeyQRCodeModalSection :id="modalBilling" :balance="balance" />
    <CustomerServiceModal :id="modalCustomerService" />
    <FormFilter
      v-model="filters"
      :isMobile="isMobile"
      @updateFilter="updateFilters($event)"
    />
    <NotFoundTransference
      v-if="!paymentOrders.count"
      :rangeDate="rangeDateNotFound"
    />
    <div class="content-transference-component">
      <PixTransactionsContent
        v-for="(day, index) in paymentOrders.days"
        :key="index"
        :content="day"
        :detailsModal="detailsModal"
        @open-modal="openModal($event)"
      />
      <AppModalReleaseDetails
        :id="detailsModal"
        :transaction="selectedOrder"
        origin="view"
        @order-refunded="getPaymentOrders"
      />
    </div>
  </div>
</template>

<script>
// modular components
import FormFilter from '@/components/shared/FormFilter';
import NotFoundTransference from '@/components/account/transference/NotFoundTransference';
import PixTransactionsContent from '@/components/pix/transactions/PixTransactionsContent';
import AppModalReleaseDetails from '@/components/account/ModalReleaseDetails.vue';
import WhiteLabel from '@/components/shared/WhiteLabel';
import CreateTransferModal from '@/components/pix/transactions/transfer/CreateTransferModal.vue';
import PayPixOrderModal from '@/components/pix/transactions/pay/PayPixOrderModal.vue';
import CustomerServiceModal from '@/components/shared/CustomerServiceModal.vue';
import SelectPixKeyQRCodeModalSection from '@/components/pix/transactions/bill/SelectPixKeyQRCodePixModalSection.vue';

// api
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';

export default {
  name: 'AppListPixTransactions',

  components: {
    FormFilter,
    NotFoundTransference,
    PixTransactionsContent,
    AppModalReleaseDetails,
    WhiteLabel,
    CreateTransferModal,
    PayPixOrderModal,
    CustomerServiceModal,
    SelectPixKeyQRCodeModalSection,
  },

  data: () => ({
    dateFilter: {
      startDate: new Date(),
      endDate: new Date(),
    },
    filters: {
      status: {
        title: 'Status',
        type: 'select',
        options: [
          { value: null, label: 'Todos os status' },
          { value: 'submitted', label: 'Enviadas' },
          { value: 'successful', label: 'Finalizadas' },
          { value: 'rejected', label: 'Reprovadas' },
          { value: 'error', label: 'Erro' },
        ],
        value: { value: null, label: 'Todos os status' },
      },
      debit: {
        title: 'Natureza do pagamento',
        type: 'select',
        options: [
          { value: null, label: 'Saídas e entradas' },
          { value: true, label: 'Saídas' },
          { value: false, label: 'Entradas' },
        ],
        value: { value: null, label: 'Saídas e entradas' },
      },
    },
    paymentOrders: {
      count: 0,
      days: [],
    },
    perPage: 15,
    offset: 0,
    maxOffset: 0,
    selectedOrder: {},
    detailsModal: 'orderDetailsModal',
    balance: {},
    modalBalanceBlocked: 'modalBalanceBlocked',
    modalTransferBase: 'modalTransferBase',
    modalPayment: 'modalPayment',
    modalBilling: 'modalBilling',
    modalCustomerService: 'modalCustomerService',
  }),

  computed: {
    isMobile() {
      return navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);
    },
    rangeDateNotFound() {
      return { end: this.dateFilter.startDate, start: this.dateFilter.endDate };
    },
    getBalance() {
      return this.balance;
    },
  },

  async mounted() {
    this.$bus.$emit('spinner-stop');
    await this.getBalanceAccount();
  },

  methods: {
    async updateFilters(filtro) {
      this.dateFilter = filtro;

      await this.getPaymentOrders();
    },

    async getPaymentOrders() {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();

      const model = {
        ...this.dateFilter,
        status: this.filters.status.value.value,
        debit: this.filters.debit.value.value,
      };
      const result = await api.pix.getPaymentOrders(model, 0, this.perPage);

      if (result.status == 200) {
        this.paymentOrders = result.data;
      }
      this.$bus.$emit('spinner-stop');
    },

    async getMorePaymentOrders() {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();

      const model = {
        ...this.dateFilter,
        status: this.filters.status.value.value,
        debit: this.filters.debit.value.value,
      };
      const result = await api.pix.getPaymentOrders(
        model,
        this.offset,
        this.perPage
      );

      if (result.status == 200) {
        if (result.data.days.length > 0)
          this.paymentOrders.days = this.paymentOrders.days.concat(
            result.data.days
          );
      }
      this.$bus.$emit('spinner-stop');
    },

    openModal(order) {
      const api = new PaymentAccountsApi();
      order.detailsLink = api.pix.mountOrderDetailsLink(order.id);
      this.selectedOrder = order;
      $(`#${this.detailsModal}`).modal();
    },

    async updateSearch() {
      await this.getPaymentOrders();
      $(`#${this.modalTransferBase}`).modal('hide');
      $(`#${this.modalPayment}`).modal('hide');
    },

    async loadMore() {
      if (this.offset + this.perPage > this.paymentOrders.count) return;
      this.offset += this.perPage;
      if (this.offset > this.maxOffset) {
        this.maxOffset = this.offset;
        await this.getMorePaymentOrders();
      }
    },

    async getBalanceAccount() {
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance('pix');
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
