<template>
  <div class="form-group col-12 col-w-full">
    <form action="#" @submit.prevent="readCode()">
      <div class="col-12 col-w-full mb-4">
        <input v-model="code" required autofocus class="form-control" />
        <small class="text-danger" v-if="!$v.code.minLength">
          O código deve ter pelo menos 10 caracteres
        </small>
      </div>

      <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
        <button type="submit" class="btn btn-md btn-orange col-12">
          Continuar
        </button>
      </WhiteLabel>
    </form>
  </div>
</template>

<script>
// component imports
import WhiteLabel from '@/components/shared/WhiteLabel';

// validation imports
import { minLength } from 'vuelidate/lib/validators';

export default {
  name: 'ReadQrCodeModalSection',

  components: {
    WhiteLabel,
  },

  data: () => ({
    code: null,
  }),

  methods: {
    readCode() {
      if (this.$v.$invalid) {
        this.$alert.error('Ops', 'Confira o código!');
        return;
      }

      this.$emit('read-code', this.code);
    },
  },

  validations: {
    code: {
      minLength: minLength(10),
    },
  },
};
</script>
