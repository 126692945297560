<template>
  <ModalBase
    :title="page.title"
    :hasFooter="false"
    size="transference-size"
    :id="id"
  >
    <div slot="content">
      <ReadQrCodeModalSection
        v-if="page.id === sections.readQrCode.id"
        @read-code="readCode($event)"
      />
      <DisplayPixOrderPayloadModalSection
        v-if="page.id === sections.displayPayload.id"
        :qrCodeContent="qrCodeContent"
        :description="destination.description"
        @next-section="payloadConfirmed()"
      />
      <SetTransferAmountModalSection
        v-if="page.id === sections.setAmount.id"
        :balance="balance"
        @set-amount="setAmount($event)"
      />
      <SetTransferDescriptionModalSection
        v-if="page.id === sections.setDescription.id"
        :preLoadedDescription="destination.description"
        @set-description="setDescription($event)"
      />
      <AuthCodeModalSection
        v-if="page.id === sections.authCode.id"
        :subSection="sections.authCode.subSection"
        :mobile="preset.holder.mobile"
        :email="preset.holder.email"
        @auth-sent="authSent($event)"
        @code-ready="recieveAuthCode($event)"
      />
      <ConfirmTransferModalSection
        v-if="page.id === sections.confirmation.id"
        :destination="destination"
        @confirm="sendPayment($event)"
      />
    </div>
  </ModalBase>
</template>

<script>
// component imports
import ModalBase from '@/components/shared/ModalBase';
import ReadQrCodeModalSection from '@/components/pix/transactions/pay/ReadQrCodeModalSection.vue';
import DisplayPixOrderPayloadModalSection from '@/components/pix/transactions/pay/DisplayPixOrderPayloadModalSection.vue';
import SetTransferAmountModalSection from '@/components/pix/transactions/transfer/SetTransferAmountModalSection.vue';
import SetTransferDescriptionModalSection from '@/components/pix/transactions/transfer/SetTransferDescriptionModalSection.vue';
import AuthCodeModalSection from '@/components/pix/transactions/transfer/AuthCodeModalSection.vue';
import ConfirmTransferModalSection from '@/components/pix/transactions/transfer/ConfirmTransferModalSection.vue';
import swal from 'sweetalert2';

// api imports
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';

// error imports
import PixOrderPaymentError from '@/errors/PixOrderPaymentError';

// model imports
import { Presets } from '@/models/Presets';

export default {
  name: 'AppPayPixOrderModal',

  components: {
    ModalBase,
    ReadQrCodeModalSection,
    SetTransferAmountModalSection,
    SetTransferDescriptionModalSection,
    ConfirmTransferModalSection,
    AuthCodeModalSection,
    DisplayPixOrderPayloadModalSection,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    balance: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },

  data: () => ({
    page: {
      id: '',
      title: 'Realizar um pagamento com PIX',
    },
    sections: {
      readQrCode: {
        title: 'Insira o código do QR Code a pagar',
        id: 'readQrCode',
      },
      displayPayload: {
        title: 'Confira os detalhes do pagamento',
        id: 'displayPayload',
      },
      setAmount: {
        title: 'Qual valor deseja enviar?',
        id: 'setAmount',
      },
      setDescription: {
        title: 'Deseja incluir uma descrição na transferência?',
        id: 'setDescription',
      },
      authCode: {
        title: 'Como deseja receber o código de autenticação?',
        subSection: 'destination',
        id: 'authCode',
      },
      confirmation: {
        title: 'Confirmar pagamento',
        id: 'confirmation',
      },
    },
    destination: {
      amount: '',
      label: '',
      prefix: '',
      id: '',
      icon: '',
      key: '',
      qrCodeId: '',
      details: {
        account: {},
      },
    },
    qrCodeContent: {},
    preset: new Presets(),
  }),

  created() {
    this.setPage(this.sections.readQrCode);
    this.loadPresets();
  },

  mounted() {
    this.$bus.$on('cleanInput', (result) => {
      this.reset();
    });
  },

  methods: {
    setPage(section) {
      this.page.id = section.id;
      this.page.title = section.title;
    },

    async loadPresets() {
      const api = new AccountApi();
      this.preset = await api.presets();
    },

    reset() {
      this.setPage(this.sections.readQrCode);
      this.destination = {
        amount: '',
        label: '',
        prefix: '',
        id: '',
        icon: '',
        key: '',
        qrCodeId: '',
        endToEnd: null,
        details: {
          account: {},
        },
      };
      this.qrCodeContent = {};

      this.sections.authCode.title =
        'Como deseja receber o código de autenticação?';
      this.sections.authCode.subSection = 'destination';
    },

    async readCode(code) {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();

      const result = await api.pix.readQrCode(code);

      if (result.status == 200) {
        if (result.data.confirmed) {
          this.destination.details.account = {
            institutionName: result.data.account.institution,
            type: result.data.account.type,
            branch: result.data.account.branch,
            number: result.data.account.number,
            taxDocument: result.data.account.taxDocument,
            name: result.data.account.name,
          };

          this.type = result.data.type;
          this.destination.key = result.data.key;

          this.qrCodeContent = result.data;

          if (result.data.finalAmount) {
            this.destination.amount = result.data.finalAmount;
          }

          if (this.type == 'static') {
            this.destination.description = result.data.payload.description;
          } else {
            const payer = result.data.payload.payer;
            let payerNote = null;

            if (payer) payerNote = payer.payerNote ? payer.payerNote : null;

            this.destination.description = payerNote;
            this.destination.qrCodeId = result.data.internalReference;
            this.destination.endToEnd = result.data.id;
          }
          this.setPage(this.sections.displayPayload);
        } else if (result.data.error) {
          this.$alert.error('Ops', result.data.error.message);
        } else {
          this.$alert.error('Ops', 'Algo deu errado');
        }
      } else if (result.status == 422) {
        const modelError = new PixOrderPaymentError({
          error: result.data.error,
        });
        this.$alert.error('Ops', modelError.getMessage());
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
      }

      this.$bus.$emit('spinner-stop');
    },

    payloadConfirmed() {
      if (this.destination.amount) this.setPage(this.sections.setDescription);
      else this.setPage(this.sections.setAmount);
    },

    setAmount(amount) {
      this.destination.amount = amount;

      this.setPage(this.sections.setDescription);
    },

    setDescription(description) {
      this.destination.description = description;

      this.setPage(this.sections.authCode);
    },

    authSent(mobile) {
      this.sections.authCode.title = `Insira o código enviado para seu ${
        mobile ? 'celular' : 'email'
      }.`;

      this.sections.authCode.subSection = 'code';
      this.setPage(this.sections.authCode);
    },

    recieveAuthCode(authCode) {
      this.destination.authCode = authCode;

      this.setPage(this.sections.confirmation);
    },

    async sendPayment(event) {
      const api = new PaymentAccountsApi();
      this.$bus.$emit('spinner-run');

      let model = {
        amount: Number(this.destination.amount),
        description: this.destination.description,
        key: this.destination.key,
        scheduledFor: event.schedule ? event.payDate : null,
        qrCodeId: this.destination.qrCodeId,
        endToEnd: this.destination.endToEnd,
      };

      const result = await api.pix.createOrderPayment(
        model,
        this.destination.authCode
      );

      if (result.status == 200) {
        if (result.data.confirmed) {
          swal({
            title: 'Pronto',
            text: 'Ordem de pagamento enviada!',
            type: 'success',
            allowOutsideClick: false,
          }).then(() => {
            this.$emit('payment-created');
            this.reset();
          });
        } else if (!result.data.confirmed && result.data.errors === null) {
          this.$alert.info('Pronto', 'Ordem de pagamento agendada!');
          swal({
            title: 'Pronto',
            text: 'Ordem de pagamento agendada!',
            type: 'success',
            allowOutsideClick: false,
          }).then(() => {
            this.$emit('payment-created');
            this.reset();
          });
        } else if (result.data.errors) {
          this.$alert.error('Ops', result.data.errors[0].message);
        } else {
          this.$alert.error('Ops', 'Algo deu errado');
        }
      } else if (result.status == 422) {
        const modelError = new PixOrderPaymentError({
          error: result.data.error,
        });
        this.$alert.error('Ops', modelError.getMessage());
        this.reset();
      } else if(result.status == 400) {
        this.$alert.error('Ops', result.data.errors[0]);
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.reset();
      }

      this.$bus.$emit('spinner-stop');
    },
  },
};
</script>

<style>
</style>
