<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" class="text-left">Chave</th>
        <th scope="col" class="text-left">Ocorrência</th>
        <th scope="col" class="text-left">Data solicitação</th>
        <th scope="col" class="text-left"></th>
        <th scope="col" class="text-left"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="claims.length === 0">
        <td colspan="5" class="text-center">Nenhum pedido encontrado</td>
      </tr>
      <tr v-else v-for="claim of claims" :key="claim.id">
        <td class="text-left">{{ claim.key }}</td>
        <td class="text-left">
          {{ claim.portability ? 'Portabilidade' : 'Reivindicação' }}
        </td>
        <td class="text-left">{{ claim.createdAt | dateTime }}</td>
        <td class="text-left" v-if="claim.status === 'pending'">
          <span class="badge badge-pill badge-pending w-75 p-2 pl-2 pr-2"
            >Conclusão pendente</span
          >
        </td>
        <td class="text-left" v-else-if="claim.status === 'canceled'">
          <span class="badge badge-pill badge-secondary w-75 p-2 pl-2 pr-2"
            >Cancelado</span
          >
        </td>
        <td class="text-left" v-else-if="claim.status === 'approved'">
          <span class="badge badge-pill badge-success w-75 p-2 pl-2 pr-2"
            >Aprovado</span
          >
        </td>
        <td class="text-left">
          <div class="dropdown" v-if="claim.status === 'pending'">
            <WhiteLabel>
              <button
                type="button"
                class="btn btn-outline-orange btn-pill dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Ações
              </button>
              <ul class="dropdown-menu">
                <Fragment
                  v-if="claim.status === 'pending' && claim.type === 'donor'"
                >
                  <li>
                    <a
                      href="javascript:;"
                      class="dropdown-item"
                      @click.prevent="approveClaim(claim.id)"
                    >
                      Aprovar <i class="fa fa-check" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:;"
                      class="dropdown-item"
                      @click.prevent="
                        validateCancelClaim(
                          claim.id,
                          claim.keyType,
                          claim.type === 'donor',
                          claim.key
                        )
                      "
                    >
                      Recusar <i class="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </li>
                </Fragment>
                <Fragment
                  v-else-if="
                    claim.status === 'pending' && claim.type === 'claimer'
                  "
                  ><li>
                    <a
                      href="javascript:;"
                      class="dropdown-item"
                      @click.prevent="
                        validateCancelClaim(
                          claim.id,
                          claim.keyType,
                          claim.type === 'donor',
                          claim.key
                        )
                      "
                    >
                      Cancelar <i class="fa fa-times" aria-hidden="true"></i>
                    </a>
                  </li>
                </Fragment>
              </ul>
            </WhiteLabel>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import swal from 'sweetalert2';
import WhiteLabel from '../../shared/WhiteLabel.vue';
import { Fragment } from 'vue-fragment';

export default {
  components: { WhiteLabel, Fragment },
  data: () => ({
    claims: [],
  }),
  methods: {
    async approveClaim(claimId) {
      swal
        .fire({
          title: 'Tem certeza que deseja aprovar?',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async (result) => {
          if (result.value) {
            this.$bus.$emit('spinner-run');
            const api = new PaymentAccountsApi();
            const result = await api.pix.approveClaim(claimId);
            this.$bus.$emit('spinner-stop');
            let message = '';

            if (result.status == 200) {
              const response = result.data;
              if (!response.error) {
                swal.fire({
                  title: 'Aprovada',
                  timer: 2500,
                  showConfirmButton: false,
                  type: 'success',
                });
                this.$emit('approved');
                return;
              }

              if (!response.confirmed && response.error !== null)
                message = response.error.message;
            }

            swal.fire('Falha ao aprovar!', message, 'error');
          }
        });
    },
    async validateCancelClaim(claimId, keyType, isDonor, claimKey) {
      swal
        .fire({
          title: `Tem certeza que deseja ${isDonor ? 'recusar' : 'cancelar'}?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then(async (result) => {
          if (result.value) {
            this.$emit('cancellationRequested', {
              claimId,
              keyType,
              claimKey,
              isDonor,
            });
          }
        });
    },
    async listClaims(filters) {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      const result = await api.pix.getClaims(filters);

      if (result.status == 200) {
        this.claims = result.data.claims;
      }
      this.$bus.$emit('spinner-stop');
    },
  },
};
</script>
<style scoped>
.dropdown-menu button {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dropdown button:active:focus {
  background-color: transparent !important;
  color: black;
  outline: none !important;
  box-shadow: none !important;
}
</style>
