import CustomApiError from './CustomError';

export default class PixOrderPaymentError extends CustomApiError {
  toDictionary() {
    return {
        QRCODE_NOT_FOUND: 'QR Code não encontrado.',
        QRCODE_GENERATE_IMAGE_FAIL: 'Geração da imagem QR Code Falhou.',
        EXPIRED_QR_CODE: 'Não é possivel gerar imagem para um QR Code vencido.',
    };
  }
}
