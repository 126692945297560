<template>
  <div class="ted-container">
    <div class="mb-4">
      <div class="d-flex form-group">
        <div class="form-group col-1">
          <i class="icon-small icon-wallet gray" />
        </div>
        <div class="form-group col-11">
          <label class="small-price-label--inline">
            <strong>Destino</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">{{
            qrCodeContent.account.name
          }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">{{
            qrCodeContent.account.taxDocument
          }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">
            <strong>Agência:</strong>
          </label>
          {{ qrCodeContent.account.branch }}
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">
            <strong>
              Conta
              {{ getAccountType(qrCodeContent.account.type) }}:
            </strong>
          </label>
          {{ qrCodeContent.account.number }}
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11">
          <label class="small-price-label--inline">{{
            qrCodeContent.account.institution
          }}</label>
        </div>
      </div>
    </div>

    <div v-if="qrCodeContent.type === 'dynamic'">
      <div class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small icon-user gray" />
          </div>
          <div class="form-group col-11">
            <label class="small-price-label--inline">
              <strong>Pagador</strong>
            </label>
          </div>
        </div>
        <div v-if="qrCodeContent.payload.payer !== null" class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label">
              {{ qrCodeContent.payload.payer.payerName }}
            </label>
          </div>
        </div>
        <div v-if="qrCodeContent.payload.payer !== null" class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              {{ qrCodeContent.payload.payer.payerTaxDocument }}
            </label>
          </div>
        </div>
        <div v-if="qrCodeContent.payload.payer !== null" class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              {{ qrCodeContent.payload.payer.payerNote }}
            </label>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small icon-info gray" />
          </div>
          <div class="form-group col-11">
            <label class="small-price-label--inline">
              <strong>Informações gerais</strong>
            </label>
          </div>
        </div>
        <div v-for="(item, index) in qrCodeContent.payload.info" :key="index">
          <div v-if="index < 3" class="d-flex form-group">
            <div class="form-group offset-1 col-11">
              <label class="small-price-label--inline">
                <strong>{{ item.name }}:</strong>
              </label>
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small fa fa-money gray" />
          </div>
          <div class="form-group col-11">
            <label class="small-price-label--inline">
              <strong>Valores</strong>
            </label>
          </div>
        </div>
        <div class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              <strong>Valor inicial: </strong>
            </label>
            {{
              qrCodeContent.amount
                | currency("R$ ", 2, {
                  decimalSeparator: ",",
                  thousandsSeparator: ".",
                })
            }}
          </div>
        </div>
        <div v-if="isExpired" class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              <strong>Juros: </strong>
            </label>
            {{
              qrCodeContent.payload.interest
                | currency("R$ ", 2, {
                  decimalSeparator: ",",
                  thousandsSeparator: ".",
                })
            }}
          </div>
        </div>
        <div v-if="isExpired" class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              <strong>Multas: </strong>
            </label>
            {{
              qrCodeContent.payload.fines
                | currency("R$ ", 2, {
                  decimalSeparator: ",",
                  thousandsSeparator: ".",
                })
            }}
          </div>
        </div>
        <div v-if="isEarly" class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              <strong>Desconto: </strong>
            </label>
            {{
              qrCodeContent.payload.discount
                | currency("R$ ", 2, {
                  decimalSeparator: ",",
                  thousandsSeparator: ".",
                })
            }}
          </div>
        </div>
        <div class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              <strong>Valor final: </strong>
            </label>
            {{
              qrCodeContent.finalAmount
                | currency("R$ ", 2, {
                  decimalSeparator: ",",
                  thousandsSeparator: ".",
                })
            }}
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small icon-calendar gray" />
          </div>
          <div class="form-group col-11">
            <label class="small-price-label--inline">
              <strong>Emitido: </strong>
            </label>
            {{ qrCodeContent.payload.createdAt | moment("DD/MM/YYYY") }}
          </div>
        </div>
        <div class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              <strong>Vencimento: </strong>
            </label>
            {{ qrCodeContent.payload.dueDate | moment("DD/MM/YYYY") }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        qrCodeContent.type === 'static' && qrCodeContent.payload.description
      "
    >
      <div class="d-flex form-group">
        <div class="form-group col-1">
          <i class="icon-small icon-info gray" />
        </div>
        <div class="form-group col-11">
          <label class="small-price-label--inline">
            <strong>{{ qrCodeContent.payload.description }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div
          v-if="qrCodeContent.finalAmount"
          class="form-group offset-1 col-11"
        >
          <label class="small-price-label--inline">
            <strong>Valor: </strong>
          </label>
          {{
            qrCodeContent.finalAmount
              | currency("R$ ", 2, {
                decimalSeparator: ",",
                thousandsSeparator: ".",
              })
          }}
        </div>
        <div v-else class="form-group offset-1 col-11">
          <label class="small-price-label--inline">
            <strong>Valor: </strong>
          </label>
          A definir
        </div>
      </div>
    </div>
    <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
      <button @click="nextSection()" class="btn btn-md btn-orange col-12">
        Continuar
      </button>
    </WhiteLabel>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import moment from 'moment';

export default {
  name: 'AppDisplayPixOrderPayloadModalSection',

  components: {
    WhiteLabel,
  },

  props: {
    qrCodeContent: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: 'E54403563202101151003R9JJwSvuucr',
          confirmed: true,
          type: 'dynamic',
          amount: 9.5,
          finalAmount: 9.5,
          account: {
            institution: 'PAGGCERTO SOLUCOES EM PAGAMENTO S.A. ',
            type: 'checking',
            branch: '0001',
            number: '0000000007',
            taxDocument: '92.***.***/0001-0*',
            name: 'Teste comissão cartão 32',
          },
          payload: {
            payer: {
              payerTaxDocument: '***.811.368-**',
              payerName: 'Ricardo Nelson Tomás Lima',
              payerNote: 'Teste cobrança com QRCode',
            },
            dueDate: '2021-01-20T00:00:00',
            expiredAt: '2021-01-21T00:00:00',
            createdAt: '2021-01-14T18:38:46.337Z',
            interest: 1.0,
            fines: 2.0,
            discount: 0.5,
            info: [
              {
                name: 'Produto',
                value: 'Celular iPhone 8 Plus',
              },
              {
                name: 'Nota Fiscal',
                value: '2381722',
              },
            ],
          },
          key: '+5521992186365',
          keyType: 'mobile',
          internalReference: 'cca262e90df04424a8cfc9acb58b5b39',
          error: null,
        };
      },
    },
  },

  computed: {
    isExpired() {
      const currentDate = new Date();
      const expiredDate = new Date(this.qrCodeContent.payload.dueDate);

      return currentDate > expiredDate;
    },
    isEarly() {
      const currentDate = new Date();
      const dueDate = new Date(this.qrCodeContent.payload.dueDate);

      return currentDate <= dueDate;
    },
  },

  methods: {
    getAccountType(accountType) {
      switch (accountType) {
      case 'checking':
        return 'corrente';
      case 'savings':
        return 'poupança';
      case 'salary':
        return 'salário';

      default:
        break;
      }
    },
    nextSection() {
      this.$emit('next-section');
    },
  },
};
</script>

<style lang="scss" scoped>
.ted-container {
  padding: 1rem;
}
.input-money {
  font-size: 48px;
  max-width: 100%;
  border: none;
  outline: none;
  font-weight: lighter;
  border-bottom: solid thin;
  border-color: #a1a4b1;
}
.mt-lg {
  margin-top: 80px;
}
.small-label {
  display: block;
  text-transform: uppercase;
  color: #7d8287;
  font-size: 12px;
}
.price-label {
  display: block;
  font-size: 30px;
  color: #7d8287;
}
.small-price-label {
  display: block;
  color: #7d8287;

  &--inline {
    display: inline;
  }
}
.icon-small {
  width: 20px;
  height: 20px;
}
</style>
