<template>
  <div>
    <div class="header-box pt-2 pb-4 mb-5">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-xl-12">
          <form class="container" autocomplete="off">
            <div class="row mb-1">
              <div class="col-12 mt-3">
                <WhiteLabel>
                  <h5 class="font-weight-light blue">Dados do PIX</h5>
                </WhiteLabel>
              </div>
              <div
                class="form-group col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-3"
              >
                <label class="mb-2 light">Data Criação</label>
                <div class="form-group">
                  <span>
                    {{ qrCode.createdAt | moment('DD/MM/YYYY') }}
                  </span>
                </div>
              </div>
              <div
                class="form-group col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-3 text-left"
              >
                <label class="light">Valor</label>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="formatAmount"
                    class="form-control"
                    name=""
                    maxlength="120"
                    :disabled="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3 text-left"
              >
                <label class="light">Chave Pix</label>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="qrCode.key"
                    class="form-control"
                    name=""
                    maxlength="120"
                    :disabled="false"
                  />
                </div>
              </div>
              <div
                class="form-group col-12 col-w-full col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-3"
              >
                <WhiteLabel class="d-flex col-12 pl-0 ml-3">
                  <label class="radio-selector d-flex">
                    <input
                      type="checkbox"
                      v-model="qrCode.base64"
                      :disabled="true"
                    />
                    <div class="checkbox-badge mt-1 mr-2"></div>
                    <label class="mb-0 d-flex light">Base 64</label>
                  </label>
                </WhiteLabel>
              </div>
              <div
                class="form group col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3 text-left"
              >
                <label class="light mt-1">QR Code</label>
                <div class="form-group">
                  <textarea
                    v-model="qrCode.value"
                    autofocus
                    class="form-control input-area col-12 col-w-full"
                    rows="4"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="mb-3 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div class="d-flex form-group text-center">
                  <div class="form-group offset-1 col-11 long-word">
                    <img :src="qrCodeImage" class="col-10" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3 text-left"
              >
                <label class="light">Tipo QR Code</label>
                <select
                  class="form-control col-12"
                  v-model="qrCode.type"
                  :disabled="true"
                >
                  <option
                    v-for="typeQr of qrCodeType"
                    :key="typeQr.id"
                    :value="typeQr.id"
                  >
                    {{ typeQr.description }}
                  </option>
                </select>
              </div>
              <div
                v-if="hasDescription"
                class="form-group col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8 mb-3 text-left"
              >
                <label class="light">Descrição</label>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="qrCode.payload.description"
                    class="form-control"
                    name=""
                    maxlength="120"
                    :disabled="true"
                  />
                </div>
              </div>
              <div
                v-if="hasDueDate"
                class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3 text-left"
              >
                <label>Data de Vencimento</label>
                <div class="form-group">
                  <span>{{
                    qrCode.payload.dueDate | moment('DD/MM/YYYY')
                  }}</span>
                </div>
              </div>
              <div
                v-else-if="hasExpiredAt"
                class="col-12 col-sm-4 col-md-6 col-lg-4 col-xl-4 mb-3 text-left"
              >
                <label>Data de Expiração</label>
                <div class="form-group">
                  <span>{{
                    qrCode.payload.expiredAt | moment('DD/MM/YYYY')
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="qrCode.type !== 'static'" class="row">
              <div
                class="form-group col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              >
                <label for="interest">Juros</label>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="formatInterest"
                    class="form-control"
                    name=""
                    maxlength="120"
                    :disabled="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-12 col-lg-4 col-md-4 col-sm-4 col-xl-4"
              >
                <label for="fine">Multa</label>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="formatFines"
                    class="form-control"
                    name=""
                    maxlength="120"
                    :disabled="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-12 col-lg-4 col-md-4 col-xl-4 col-sm-4"
              >
                <label for="discount">Desconto</label>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="formatDiscount"
                    class="form-control"
                    name=""
                    maxlength="120"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div v-if="hasPayer" class="row mt-1">
              <div class="col-sm-12 mt-1">
                <WhiteLabel>
                  <h5 class="font-weight-light blue">Dados do Pagador</h5>
                </WhiteLabel>
              </div>
              <div
                class="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 text-left"
              >
                <label>Nome</label>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="qrCode.payload.payer.payerName"
                    class="form-control"
                    name=""
                    maxlength="120"
                    :disabled="true"
                  />
                </div>
              </div>
              <div
                class="form-group col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6 mb-4"
              >
                <label for="cpfcnpj">CPF/CNPJ</label>
                <input
                  autofocus
                  class="form-control input col-12 col-w-full"
                  v-model="qrCode.payload.payer.payerTaxDocument"
                  :disabled="true"
                />
              </div>
              <div
                v-if="hasPayerNote"
                class="form-group col-w-full col-12 col-lg-6 col-md-12 col-xl-12 col-sm-12 mb-4"
              >
                <label for="note">Nota</label>
                <input
                  autofocus
                  class="form-control input col-12 col-w-full"
                  v-model="qrCode.payload.payer.payerNote"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="row" v-if="hasInfo">
              <div class="col-sm-12">
                <WhiteLabel>
                  <h5 class="font-weight-light pull-left blue">
                    Informações Extras
                  </h5>
                </WhiteLabel>
              </div>
              <div
                class="form-group col-12 col-w-full col-lg-12 mb-1"
                align="center"
              >
                <ExtraInformationTable :info="payload.info" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrCode } from '@/models/QrCode';
import ExtraInformationTable from './ExtraInformationTable.vue';
import WhiteLabel from '@/components/shared/WhiteLabel';
import PaymentAccountApi from '@/services/v1/PaymentAccountsApi';
import QrCodeError from '@/errors/QrCodeError';

export default {
  name: 'QrCodeDetails',
  async mounted() {
    this.qrCode = { ...this.$route.params.qrCodeDetails };
    this.payload = { ...this.$route.params.qrCodeDetails.payload };
    await this.getQrCodeImage(this.qrCode.id);
  },
  data() {
    return {
      qrCodeImage: null,
      payload: null,
      qrCode: new QrCode(),
      qrCodeType: [
        { id: 'dynamic', description: 'Dinâmico' },
        { id: 'static', description: 'Simples' },
      ],
    };
  },
  computed: {
    hasPayer() {
      if (this.payload === null) return false;
      return (
        this.payload.hasOwnProperty('payer') && this.payload.payer !== null
      );
    },
    hasInfo() {
      if (this.payload === null) return false;
      return this.payload.hasOwnProperty('info') && this.payload.info !== null;
    },
    hasPayerNote() {
      if (this.payload.payer.payerNote === null) return false;
      return (
        this.payload.payer.hasOwnProperty('payerNote') &&
        this.payload.payer.payerNote !== null
      );
    },
    hasDescription() {
      if (this.payload === null) return false;
      return (
        this.payload.hasOwnProperty('description') &&
        this.payload.description !== null
      );
    },
    formatAmount() {
      return this.formatValue(this.qrCode.amount);
    },
    formatDiscount() {
      return this.formatValue(this.checkEmptyProp('discount'));
    },
    formatFines() {
      return this.formatPercent(this.checkEmptyProp('fines'));
    },
    formatInterest() {
      return this.formatPercent(this.checkEmptyProp('interest'));
    },
    hasExpiredAt() {
      if (this.payload === null) return false;
      return (
        this.payload.hasOwnProperty('expiredAt') &&
        this.payload.expiredAt !== null
      );
    },
    hasDueDate() {
      if (this.payload === null) return false;
      return (
        this.payload.hasOwnProperty('dueDate') && this.payload.dueDate !== null
      );
    },
  },
  components: {
    WhiteLabel,
    ExtraInformationTable,
  },
  methods: {
    encodeBase64(data) {
      return Buffer.from(data).toString('base64');
    },

    encodeBase64ToJson(base64String) {
      const jsonString = Buffer.from(base64String, 'base64').toString();
      return JSON.parse(jsonString);
    },

    async getQrCodeImage(id) {
      const api = new PaymentAccountApi();
      const result = await api.pix.generateQrCodeImage(id);
      var base64EncodedStr = this.encodeBase64(result.data);

      if (result.status === 200) {
        this.qrCodeImage = `data:image/jpeg;base64,${base64EncodedStr}`;
      } else if (result.status === 422) {
        let errorData = this.encodeBase64ToJson(base64EncodedStr);
        const modelError = new QrCodeError({ error: errorData.error });
        this.$alert.error('Ops', modelError.getMessage());
      } else if (result.status === 500) {
        this.$alert.error('Ops', 'Algo deu errado ao gerar imagem QR Code.');
      }
    },

    checkEmptyProp(prop) {
      let value = 0;

      if (this.payload !== null) {
        if (this.payload.hasOwnProperty(prop) && this.payload[prop] !== null) {
          value = this.payload[prop];
        }
      }
      return value;
    },
    formatValue(value) {
      if (value === 0 || value === null) {
        return 'R$ 0,00';
      }
      return `R$ ${value.toFixed(2).toString().replace('.', ',')}`;
    },
    formatPercent(value) {
      if (value === 0 || value === null) {
        return '0,00%';
      }
      return `${value.toFixed(2).toString().replace('.', ',')}%`;
    },
  },
};
</script>
