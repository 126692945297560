import { withParams } from 'vuelidate/lib';

const minCurrentDate = withParams({ type: 'minCurrentDate' }, value => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    let date = new Date(value.getFullYear(), value.getMonth() + 1, value.getDate());
    let currentDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

    if (date < currentDate) {
        return false;
    }
    return true;
});

export { minCurrentDate };
