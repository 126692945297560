<template>
  <div class="row justify-content-center">
    <div class="col-12 col-w-full col-lg-12 col-xl-10">
      <div class="card mt-5">
        <div class="d-flex">
          <h3 class="font-weight-light pull-left ml-3">{{ title }}</h3>
          <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
            <a href="#" @click="$router.go(-1)">
              <i class="icon-arrow-left-circle"></i> Voltar
            </a>
          </WhiteLabel>
        </div>
        <hr />
        <form>
          <div class="card-content mb-5">
            <div class="form-row">
              <div class="col-sm-12">
                <WhiteLabel>
                  <h4 class="font-weight-light blue">Dados do PIX</h4>
                </WhiteLabel>
              </div>

              <DynamicPixForm
                :hasExpirationDate="hasExpirationDate"
                @next-step="nextStep($event)"
              />
            </div>
            <hr />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import { minValue } from 'vuelidate/lib/validators';
import DynamicPixForm from '@/components/pix/transactions/bill/pix-forms/DynamicPixForm.vue';

export default {
  name: 'InstantPix',

  data: () => ({
    showError: false,
    title: 'Código QR Imediato',
    model: {},
    balance: {},
  }),
  props: {
    hasExpirationDate: {
      type: Boolean,
      required: true,
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    WhiteLabel,
    DynamicPixForm,
  },

  mounted() {
    this.balance = this.$route.params.balance;
    if (this.hasExpirationDate) this.title = 'Código QR com Vencimento';
  },

  validations: {
    amount: {
      minValue: minValue(0.01),
    },
  },
  computed: {},
  methods: {
    nextStep(model) {
      this.model = model;
      // this.model.key = this.key;
      this.$emit('set-pix-data', this.model);
    },
  },
};
</script>
