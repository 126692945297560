<template>
  <div>
    <div class="form-group col-12 col-w-full">
      <form
        v-for="key in keys"
        :key="key.id"
        action="#"
        @submit.prevent="fillDetails()"
      >
        <div v-if="key.destinationId == destination.id">
          <div v-if="key.destinationId == 'bankAccount'">
            <div class="col-12 col-w-full col-lg-11 mb-4">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i
                      :class="`${destination.icon} fa-md`"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <input
                  v-model="pixInstitution.name"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
            </div>

            <div class="form-group col-12 col-lg-9 mb-4">
              <select
                v-model="details.accountType"
                required
                class="form-control text-center"
                name="accountType"
              >
                <option value>Tipo de conta</option>
                <option value="checking">Corrente</option>
                <option value="savings">Poupança</option>
                <option value="salary">Salário</option>
              </select>
            </div>

            <div class="col-12 col-w-full col-lg-9 mb-4">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-id-card-o fa-md" aria-hidden="true" />
                  </span>
                </div>
                <input
                  v-model="details.name"
                  required
                  type="text"
                  class="form-control"
                  placeholder="Nome do favorecido"
                  maxlength="120"
                />
              </div>
            </div>

            <div class="col-12 col-w-full col-lg-9 mb-4">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-id-card-o fa-md" aria-hidden="true" />
                  </span>
                </div>
                <input
                  v-model="details.taxDocument"
                  required
                  type="text"
                  class="form-control"
                  placeholder="CPF / CNPJ"
                  v-mask="['##.###.###/####-##', '###.###.###-##']"
                />
              </div>
              <small class="text-danger" v-if="!$v.details.taxDocument.cpfCnpj">
                O documento está invalido
              </small>
            </div>

            <div class="col-12 col-w-full col-lg-9 mb-4">
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i
                      :class="`${destination.icon} fa-md`"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <input
                  v-model="details.branchNumber"
                  required
                  autofocus
                  type="text"
                  class="form-control"
                  placeholder="agência"
                  v-mask="'######'"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-w-full col-lg-9 mb-4">
            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i :class="`${destination.icon} fa-md`" aria-hidden="true" />
                </span>
              </div>
              <input
                v-if="key.destinationId == 'aleatory'"
                v-model="details.value"
                required
                type="text"
                class="form-control"
                :placeholder="destination.label"
                v-mask="key.mask"
              />
              <input
                v-if="key.destinationId == 'taxDocument'"
                v-model="details.value"
                required
                type="text"
                class="form-control"
                :placeholder="destination.label"
                v-mask="['##.###.###/####-##', '###.###.###-##']"
              />
              <input
                v-if="key.destinationId == 'mobile'"
                v-model="details.value"
                required
                type="text"
                class="form-control"
                :placeholder="destination.label"
                v-mask="['(##) #####-####', '(##) ####-####']"
              />
              <input
                v-if="key.destinationId == 'email'"
                v-model="details.value"
                required
                autofocus
                type="text"
                class="form-control"
                :placeholder="destination.label"
              />
              <input
                v-if="key.destinationId == 'bankAccount'"
                v-model="details.value"
                required
                type="text"
                class="form-control"
                :placeholder="destination.label"
                v-mask="[
                  '#############-X',
                  '############-X',
                  '###########-X',
                  '##########-X',
                  '#########-X',
                  '########-X',
                  '#######-X',
                  '######-X',
                  '#####-X',
                  '####-X',
                  '###-X',
                  '##-X',
                  '#-X',
                ]"
              />
            </div>

            <small class="text-danger" v-if="!$v.details.value.failed">
              {{ destination.prefix.toUpperCase() }}
              {{ destination.label }} está invalid{{ destination.prefix }}
            </small>
          </div>

          <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
            <button type="submit" class="btn btn-md btn-orange col-12">
              Continuar
            </button>
          </WhiteLabel>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// component imports
import WhiteLabel from '@/components/shared/WhiteLabel';

// validation imports
import { minLength, email } from 'vuelidate/lib/validators';
import { cpfCnpj } from '@/validations/cpfCnpj';
import { phone } from '@/validations/phone';

export default {
  name: 'InsertOrderDetailsModalSection',

  components: {
    WhiteLabel,
  },

  props: {
    destination: {
      type: Object,
      required: true,
      default: () => {
        return {
          label: '',
          prefix: '',
          // cpf | cnpj | mobile | email | aleatory | bankAccount
          id: '',
          icon: '',
        };
      },
    },

    pixInstitution: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: '',
          number: '',
          code: '',
        };
      },
    },
  },

  data: () => ({
    keys: {
      taxDocument: {
        destinationId: 'taxDocument',
        mask: ['###.###.###-##', '##.###.###/####-##'],
      },
      mobile: {
        destinationId: 'mobile',
      },
      email: {
        destinationId: 'email',
      },
      aleatory: {
        destinationId: 'aleatory',
        mask: 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX',
      },
      bankAccount: {
        destinationId: 'bankAccount',
        mask: [
          '#############-X',
          '############-X',
          '###########-X',
          '##########-X',
          '#########-X',
          '########-X',
          '#######-X',
          '######-X',
          '#####-X',
          '####-X',
          '###-X',
          '##-X',
          '#-X',
        ],
      },
    },

    details: {
      value: '',
      branchNumber: '',
      name: '',
      taxDocument: '',
      accountType: '',
      institutionName: '',
      institutionNumber: '',
    },
  }),

  computed: {
    sectionTitle() {
      return `Informe ${this.destination.prefix} ${this.destination.label} do favorecido.`;
    },
  },

  mounted() {
    this.details.institutionName = this.pixInstitution.name;
    this.details.institutionNumber = this.pixInstitution.code;
  },

  methods: {
    fillDetails() {
      if (this.$v.$invalid) {
        this.$alert.error(
          'Ops',
          `${this.destination.prefix.toUpperCase()}
              ${this.destination.label} está invalid${this.destination.prefix}`
        );
        return;
      }

      if (this.destination.id !== 'bankAccount') {
        this.$emit('fill-details', { value: this.details.value });
        return;
      }

      this.$emit('fill-details', this.details);
    },
  },

  validations() {
    switch (this.destination.id) {
      case 'taxDocument':
        return {
          details: {
            value: {
              failed: cpfCnpj,
            },
          },
        };

      case 'mobile':
        return {
          details: {
            value: {
              failed: false,
            },
          },
        };

      case 'email':
        return {
          details: {
            value: {
              failed: email,
            },
          },
        };

      case 'aleatory':
        return {
          details: {
            value: {
              failed: minLength(32),
            },
          },
        };

      case 'bankAccount':
        return {
          details: {
            value: {
              failed: false,
            },
            taxDocument: {
              cpfCnpj,
            },
          },
        };

      default:
        break;
    }
  },
};
</script>
