<template>
  <ModalBase
    :title="page.title"
    :hasFooter="false"
    size="transference-size"
    :id="id"
  >
    <div slot="content">
      <SelectPixKeyModalSection
        :pixKeys="pixKeys.keys"
        @select-key="selectKey($event)"
      />
    </div>
  </ModalBase>
</template>

<script>
// component imports
import ModalBase from '@/components/shared/ModalBase';
import SelectPixKeyModalSection from '@/components/pix/transactions/bill/SelectPixKeyModalSection.vue';

// api imports
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';

export default {
  name: 'SelectPixModal',

  components: {
    ModalBase,
    SelectPixKeyModalSection,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    page: {
      id: 'selectKey',
      title: 'Realizar uma cobrança com PIX',
    },
    sections: {
      selectKey: {
        title: 'Através de qual das suas chaves deseja receber?',
        id: 'selectKey',
      },
    },
    pixKeys: {},
  }),

  created() {
    this.reset();
  },

  mounted() {
    this.setPage(this.sections.selectKey);

    this.$bus.$on('cleanInput', (result) => {
      this.reset();
    });
  },

  methods: {
    reset() {
      this.setPage(this.sections.selectKey);

      this.getKeys();
      this.model = {
        key: '',
        amount: '',
        description: '',
      };
    },

    setPage(section) {
      this.page.id = section.id;
      this.page.title = section.title;
    },

    async getKeys() {
      var paymentAccountApi = new PaymentAccountsApi();
      var result = await paymentAccountApi.pix.getKeys();

      this.pixKeys = result.data;
      const keyCount = result.data.keys.filter((key) => key.active).length;
      // TODO: alert 'voce nao tem chaves cadastradas'
      // if (!keyCount)
    },

    selectKey(key) {
      $('#modalPixKeys').modal('toggle');
      this.$bus.$emit('cleanInput');
      this.$bus.$emit('closePreferences');
      this.$emit('select-key', key);
    },
  },
};
</script>

<style>
</style>
