<template>
    <div>
      <div class="header-box pt-5 pb-4 mb-5">
        <div class="row justify-content-center mx-0">
          <div class="col-12 col-xl-12">
            <form class="container" autocomplete="off">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3 text-left">
                  <label>Tipo Data</label>
                  <select class="form-control col-12" v-model="qrCodeFilter.typeDate">
                    <option :value="'all'">Todas</option>
                    <option :value="'creation'">Data de Criação</option>
                    <option :value="'due'">Data de Vencimento</option>
                  </select>
                </div>
                <div v-if="qrCodeFilter.typeDate !== 'all'" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-3 text-left">
                  <label>Data Inicial</label>
                  <datepicker
                  v-model="qrCodeFilter.startDate"
                  input-class="form-control col-12"
                  :calendar-button="false"
                  calendar-button-icon="icon-calendar"
                  language="pt-br"
                  format="dd/MM/yyyy"
                  />
                  <small
                    class="text-danger" v-if="!isValidateDate"
                    >
                    Data inicial deve ser menor ou igual do que data final.
                  </small>
                </div>
                <div v-if="qrCodeFilter.typeDate !== 'all'" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-2 mb-3 text-left">
                  <label>Data Fim</label>
                    <datepicker
                      v-model="qrCodeFilter.endDate"
                      input-class="form-control col-lg-10"
                      :calendar-button="false"
                      calendar-button-icon="icon-calendar"
                      language="pt-br"
                      format="dd/MM/yyyy"
                    />
                    <small
                      class="text-danger" v-if="!isValidateDate"
                      >
                      Data fim deve ser maior ou igual do que data inicial.
                    </small>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5 mb-3 text-left">
                  <label>Chave Pix</label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="qrCodeFilter.pixKey"
                        class="form-control"
                        name="pixKey"
                        maxlength="120"
                      />
                    </div>
                </div>
                <div class="form-group col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 text-left">
                  <label>Tipo QR Code</label>
                  <select class="form-control col-12" v-model="qrCodeFilter.type">
                    <option :value="'all'">Todos</option>
                    <option :value="'static'">Simples</option>
                    <option :value="'dynamic'">Dinâmico</option>
                  </select>
                </div>
                <div class="form-group col-12 col-w-full col-sm-6 col-md-6 col-lg-2 col-xl-4 mt-3">
                  <label></label>
                  <WhiteLabel class="d-flex col-12 pl-0 ml-3">
                    <label class="radio-selector d-flex">
                      <input type="checkbox" v-model="qrCodeFilter.base64" />
                      <div class="checkbox-badge mt-1 mr-2"></div>
                      <label class="mb-0 d-flex">Base 64</label>
                    </label>
                  </WhiteLabel>
                </div>
                <div class="d-flex col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-4">
                  <WhiteLabel>
                    <button
                      @click.prevent="turnPage(1)"
                      class="btn btn-md btn-orange mr-2 mt-1"
                      :disabled="!isValidateDate"
                    >
                      Pesquisar
                    </button>
                  </WhiteLabel>
                  <WhiteLabel>
                    <button
                      type="button"
                      @click="resetFilters"
                      class="btn col-12 btn-md btn-outline-orange ml-1 mt-1"
                    >
                      Limpar filtro
                    </button>
                  </WhiteLabel>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <table class="table table-formatter table-striped col-12">
              <thead class="col-12">
                <tr>
                  <th scope="col">Valor</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Chave</th>
                  <th scope="col">Status</th>
                  <th scope="col">Data/Hora Criação</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="col-12" v-for="qrCode of qrCodesAll" :key="qrCode.id">
                  <td class="col-2 col-lg-2 col-xl-2">
                      <strong>{{
                        qrCode.amount
                          | currency("R$ ", 2, {
                            decimalSeparator: ",",
                            thousandsSeparator: ".",
                          })
                      }}</strong>
                  </td>
                  <td class="col-3 col-md-4 col-lg-3 col-xl-3">
                    <span v-if="qrCode.type === 'static'" class="badge badge-pill badge-success w-100 p-2 pl-2 pr-2 col-12 col-sm-12 col-md-12">Simples</span>
                    <span v-else-if="qrCode.type === 'dynamic' && qrCode.payload.dueDate !== null" class="badge badge-pill badge-warning w-100 p-2 pl-2 pr-2 col-12">Dinâmico com Vencimento</span>
                    <span v-else-if="qrCode.type === 'dynamic'" class="badge badge-pill badge-pending w-100 p-2 pl-2 pr-2 col-12">Dinâmico Imediato</span>
                  </td>
                  <td class="text-center col-2 col-lg-2 col-xl-3">
                    {{ qrCode.key }}
                  </td>
                  <td class="text-center col-1 col-lg-1 col-xl-3">
                    {{ getStatus(qrCode.status) }}
                  </td>
                  <td class="text-center col-2 col-lg-2 col-xl-2">
                    {{ qrCode.createdAt | moment('DD/MM/YYYY HH:mm:ss') }}
                  </td>
                  <td class="col-1 col-md-1 col-lg-1 col-xl-1 pl-5">
                    <div class="dropdown text-center col-2 col-xl-1">
                    <WhiteLabel>
                      <button
                        type="button"
                        class="btn btn-outline-orange btn-pill dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Ações
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link v-if="qrCode.id != ''"
                            class="dropdown-item"
                            :to="{
                              name: 'qrcode-details',
                              params: { qrCodeDetails: qrCode },
                            }">Visualizar
                            </router-link>
                            <a class="dropdown-item" href="#" @click.prevent="copyToClipboard(`#qrcode${qrCode.id}`)">
                              Copiar QR Code
                            </a>
                            <input type="hidden" :id="`qrcode${qrCode.id}`" :value="qrCode.value" />
                        </li>
                      </ul>
                    </WhiteLabel>
                  </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="this.countQrCodes === 0">
          <div class="text-center">
            <h4>Nenhum QR Code encontrado.</h4>
          </div>
        </div>
        <div class="row justify-content-center mx-0">
          <app-paginator
            :count="this.countQrCodes"
            :perPage="perPage"
            @exchange="turnPage"
          />
        </div>
      </div>
    </div>
  </template>
<script>

import PaymentAccountApi from '@/services/v1/PaymentAccountsApi';
import AppPaginator from '@/components/theme/Paginator';
import Datepicker from 'vuejs-datepicker';
import WhiteLabel from '@/components/shared/WhiteLabel';
import { QrCodeFilter, QrCodeList, QrCode } from '@/models/QrCode';
import moment from 'moment';

export default {
  name: 'ListqrCodes',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.search();
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      perPage: 30,
      qrCode: new QrCode(),
      qrCodeFilter: new QrCodeFilter(),
      qrCodeList: new QrCodeList(),
      count: 0,
    };
  },
  computed: {
    qrCodesAll() {
      return this.count === 0 ? [] : this.qrCodeList.qrCodes;
    },
    countQrCodes() {
      return this.count;
    },
    isValidateDate(){
      return (this.qrCodeFilter.startDate <= this.qrCodeFilter.endDate);
    },
  },
  methods: {
    copyToClipboard(id) {
      let codeToCopy = document.querySelector(id);
      codeToCopy.setAttribute('type', 'text');
      codeToCopy.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        successful
          ? this.$alert.info('', 'Copiado para área de transferência!')
          : this.$alert.error(
            'Ops',
            'Não foi possível copiar para área de transferência'
          );
      } catch (err) {
        this.$alert.error(
          'Ops',
          'Não foi possível copiar para área de transferência'
        );
      }

      /* unselect the range */
      codeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
    async search() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountApi();
      const result = await api.pix.getQrCode(this.qrCodeFilter);
      if (result.error) {
        this.$alert.error('Ops', 'Ocorreu algum erro ao tentar listar os dados!');
      } else {
        this.qrCodeList = result.data;
        this.count = result.data.count;
      }
      this.$bus.$emit('spinner-stop');
    },
    resetFilters() {
      this.qrCodeFilter = new QrCodeFilter();
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.qrCodeFilter.offset.index = inicio;
      await this.search();
    },
    getStatus(status) {
      let statusMap = {
        'paid': 'Pago',
        'pending': 'Pendente',
        'overdue': 'Atrasado',
        'expired': 'Expirado',
      };
      return statusMap[status];
    },

  },
  components: {
    AppPaginator,
    Datepicker,
    WhiteLabel,
  },
};
</script>

<style scoped>
.smashLine{
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
}
.table-formatter{
  table-layout: fixed;
  word-wrap: break-word;
}
</style>
