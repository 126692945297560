<template>
  <div class="col-12 col-w-full">
    <div
      v-for="destination in destinations"
      :key="destination.id"
      class="material-radio mb-4"
    >
      <WhiteLabel>
        <a
          href="#"
          class="radio-selector d-flex mb-1"
          @click="selectDestination(destination)"
        >
          <i :class="`${destination.icon} fa-lg mr-3`" aria-hidden="true" />
          <h4 class="radio-selector form-check-label lbl-filter">
            {{
              `${destination.label
                .charAt(0)
                .toUpperCase()}${destination.label.slice(1)}`
            }}
          </h4>
        </a>
      </WhiteLabel>
    </div>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'SelectOrderDestinationModalSection',

  components: {
    WhiteLabel,
  },

  props: {
    destinations: {
      type: Object,
      required: true,
      default: () => {
        return {
          taxDocument: {
            label: 'CPF / CNPJ',
            prefix: 'o',
            id: 'taxDocument',
            icon: 'fa fa-id-card-o',
          },
          mobile: {
            label: 'celular',
            prefix: 'o',
            id: 'mobile',
            icon: 'fa fa-phone',
          },
          email: {
            label: 'email',
            prefix: 'o',
            id: 'email',
            icon: 'fa fa-envelope',
          },
          aleatory: {
            label: 'chave aleatória',
            prefix: 'a',
            id: 'aleatory',
            icon: 'fa fa-key',
          },
          bankAccount: {
            label: 'conta bancária',
            prefix: 'a',
            id: 'bankAccount',
            icon: 'fa fa-envelope',
          },
        };
      },
    },
  },

  data: () => ({
    sectionTitle: 'Selecione o destino da ordem de pagamento',
  }),

  methods: {
    selectDestination(destination) {
      this.$emit('select-destination', destination);
    },
  },
};
</script>
