import { withParams } from 'vuelidate/lib';
import { noAccents } from '@/functions/noAccents';

const alphaNumericAddress = withParams({ type: 'alphaNumericAddress' }, value => {
  var str = noAccents(value);
  if (!str) return true;

  const pattern =  "^[A-Za-z0-9'\\.\\-\\s\\,]{1,}(?: [a-zA-Z\/]+){0,2}$";

  var regex = new RegExp(pattern);
  return regex.test(str);
});

export { alphaNumericAddress };
