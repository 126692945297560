<template>
  <ButtonFilter>
    <form class="form-filter" @submit.prevent="updateFilter">
      <h1 class="sub-title">Período</h1>
      <div class="form-group content-form">
        <div class="dropdown period-mobile">
          <div
            id="dropdownMenuButtonPeriod"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center
            "
          >
            <span class="text-nowrap lbl-select">{{ periodSelectLabel }}</span>
          </div>
          <div
            aria-labelledby="dropdownMenuButtonPeriod"
            class="dropdown-menu w-100"
            @click.stop
          >
            <div
              v-for="(data, index) in period"
              :key="index"
              class="material-radio"
            >
              <input
                :id="data.value"
                v-model="selectedPeriod"
                :value="data.value"
                class="form-check-input"
                type="radio"
                name="periodmobile"
              />
              <label :for="data.value" class="form-check-label lbl-filter">
                {{ data.label }}
              </label>
            </div>
            <div v-if="selectedPeriod === 4" class="report-period">
              <div
                id="calendar-date-start"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="
                  form-control
                  dropdown-select-like
                  rounded-pill
                  text-left text-lg-center text-nowrap
                "
                @click="changeCalendarDateStart"
              >
                <div
                  v-if="
                    selectedDate.start.getTime() !== selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">&nbsp;</span>
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                  <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
                  <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
                </div>
                <div
                  v-if="
                    selectedDate.start.getTime() === selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">no dia&nbsp;</span>
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                </div>
              </div>
              <div
                aria-labelledby="calendar-date-start"
                class="dropdown-menu"
                :class="{ show: calendarDateStart }"
                @click.stop
              >
                <v-date-picker
                  v-model="selectedDate"
                  :show-day-popover="false"
                  :tint-color="datePickerColor"
                  mode="range"
                  show-caps
                  is-inline
                />
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown period-web">
          <div
            id="dropdownMenuButton-c"
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center text-nowrap
            "
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div
              v-if="selectedDate.start.getTime() !== selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">&nbsp;</span>
              <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
              <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
              <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
            </div>
            <div
              v-if="selectedDate.start.getTime() === selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">no dia&nbsp;</span>
              <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
            </div>
          </div>
          <div
            class="dropdown-menu dropdown-menu-fixed calendar"
            aria-labelledby="dropdownMenuButton-c"
            @click.stop
          >
            <v-date-picker
              v-model="selectedDate"
              :show-day-popover="false"
              :tint-color="datePickerColor"
              mode="range"
              show-caps
              is-inline
              is-double-paned
            />
            <hr />
            <div class="footer-calendar">
              <div>
                <div class="dropdown">
                  <div
                    id="dropdownMenuButton-x"
                    class="
                      form-control
                      dropdown-select-like
                      rounded-pill
                      text-left text-lg-center
                    "
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click="changeShowPeriod"
                  >
                    <span class="text-nowrap lbl-select">{{
                      periodSelectLabel
                    }}</span>
                  </div>
                  <div
                    class="dropdown-menu w-100 period"
                    :class="{ show: showPeriod }"
                    aria-labelledby="dropdownMenuButton-x"
                    @click.stop
                  >
                    <div
                      v-for="(data, index) in period"
                      :key="index"
                      class="material-radio"
                    >
                      <label class="form-check-label lbl-filter">
                        <input
                          v-model="selectedPeriod"
                          :value="data.value"
                          class="form-check-input"
                          type="radio"
                          name="status-period"
                        />
                        {{ data.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field-date">
                <div class="date-start border-date">
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                </div>
                <div class="calendar-txt">
                  <p>a</p>
                </div>
                <div class="date-end border-date">
                  <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
                </div>
              </div>
              <div class="content-btn">
                <WhiteLabel>
                  <button class="btn btn-applay" @click="updateFilter">
                    Aplicar
                  </button>
                </WhiteLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(filterType, name) in filters" :key="name">
        <div class="form-group content-form">
          <div v-if="filterType.type == 'select'" class="dropdown">
            <h1 class="sub-title">{{ filterType.title }}</h1>
            <div
              id="dropdownMenuButton"
              class="
                form-control
                dropdown-select-like
                rounded-pill
                text-left text-lg-center
              "
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="text-nowrap lbl-select">{{
                filterType.value.label
              }}</span>
            </div>
            <div
              class="dropdown-menu w-100"
              aria-labelledby="dropdownMenuButton"
              @click.stop
            >
              <div
                v-for="(data, index) in filterType.options"
                :key="index"
                class="material-radio"
              >
                <input
                  v-model="filterType.value"
                  :id="`${name}-${data.value}`"
                  :value="data"
                  class="form-check-input"
                  type="radio"
                  name="statusradios"
                  @change="updateFilter()"
                />
                <label
                  :for="`${name}-${data.value}`"
                  class="form-check-label lbl-filter"
                  >{{ data.label }}</label
                >
              </div>
            </div>
          </div>
          <div v-if="filterType.type == 'checkbox'">
            <WhiteLabel>
              <label class="radio-selector d-flex mt-2">
                <input
                  v-model="filterType.value"
                  type="checkbox"
                  @change="updateFilter()"
                />
                <div class="checkbox-badge mt-1 mr-2"></div>
                {{ filterType.title }}
              </label>
            </WhiteLabel>
          </div>
        </div>
      </div>

      <div v-if="actionButton.display" class="form-group content-form">
        <WhiteLabel>
          <button
            type="button"
            class="btn btn-outline btn-sm"
            @click="actionButtonClick()"
          >
            {{ actionButton.text }}
            <i :class="actionButton.icon" aria-hidden="true" />
          </button>
        </WhiteLabel>
      </div>

      <div class="d-flex d-md-none mt-5">
        <button type="submit" class="btn btn-send-filter">
          Aplicar filtros
        </button>
      </div>
    </form>
  </ButtonFilter>
</template>

<script>
import 'v-calendar/lib/v-calendar.min.css';
import DateFilter from '@/models/DateFilter';
import moment from 'moment';
import { mask } from 'vue-the-mask';

import ButtonFilter from '@/components/shared/ButtonFilter';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppFormFilter',

  components: {
    ButtonFilter,
    WhiteLabel,
  },

  model: {
    prop: 'filters',
    event: 'update',
  },

  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
    actionButton: {
      type: Object,
      required: false,
      default: () => {
        return {
          text: '',
          icon: '',
          display: false,
        };
      },
    },
    filters: {
      type: Object,
      required: true,
      default: () => {
        return {
          status: {
            title: 'Status',
            type: 'select',
            options: [
              { value: 'all', label: 'Todos os status' },
              { value: 'approved', label: 'Confirmadas' },
            ],
            value: { value: 'all', label: 'Todos os status' },
          },
          finalized: {
            title: 'Incluir transações finalizadas',
            type: 'checkbox',
            value: true,
          },
        };
      },
    },
  },

  data: () => ({
    showPeriod: false,
    calendarDateStart: false,
    period: [
      { value: 1, label: 'Últimos 7 dias' },
      { value: 2, label: 'Últimos 30 dias' },
      { value: 3, label: 'Últimos 3 meses' },
      { value: 4, label: 'Informar período' },
    ],
    filter: new DateFilter(),
    selectedDate: {
      start: new Date().addDays(-7),
      end: new Date(),
    },
    selectedPeriod: 1,
    listMoreTransfers: false,
  }),

  computed: {
    periodSelectLabel: function () {
      let objetc = this.period.filter((s) => s.value === this.selectedPeriod);
      if (objetc.length > 0) {
        return objetc[0].label;
      } else {
        return 'Selecione Período';
      }
    },
    datePickerColor: function () {
      return window.localStorage.getItem('tertiaryColor') || '#FF7606';
    },
  },

  mounted() {
    this.updateFilter();
  },

  methods: {
    changeShowPeriod(event) {
      this.showPeriod = !this.showPeriod;
    },
    changeCalendarDateStart(event) {
      this.calendarDateStart = !this.calendarDateStart;
    },
    updateFilter() {
      this.$emit('updateFilter', this.filter);
    },
    actionButtonClick() {
      this.$emit('action-button-click', this.filter);
    },
  },

  watch: {
    'selectedDate.start': function (newValue) {
      this.filter.startDate = moment(newValue).format('YYYY-MM-DD');
    },
    'selectedDate.end': function (newValue) {
      this.filter.endDate = moment(newValue).format('YYYY-MM-DD');
    },
    numberPage: async function (newValue) {
      await this.turnPage(newValue);
    },
    moreTransfers: async function (newValue) {
      await this.getMoreTransfers();
    },
    isMobile: function (newValue) {
      this.isMobile = newValue;
    },
    selectedPeriod: function (newValue) {
      switch (newValue) {
        case 1:
          this.selectedDate.start = new Date().addDays(-7);
          this.selectedDate.end = new Date();
          break;
        case 2:
          this.selectedDate.start = new Date().addDays(-30);
          this.selectedDate.end = new Date();
          break;
        case 3:
          this.selectedDate.start = new Date().addDays(-90);
          this.selectedDate.end = new Date();
          break;
        case 4:
          break;
      }
    },
  },

  directives: {
    mask,
  },
};
</script>
