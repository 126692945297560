import axios from 'axios';

const customAxios = axios.create({
  headers: {},
});

export default class IbgeApi {
  async searchStates() {
    try {
      const request = await customAxios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
      return request.data;
    } catch (error) {
      return [];
    }
  }

  async searchCities(id) {
    try {
      const request = await customAxios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`);
      return request.data;
    } catch (error) {
      return [];
    }
  }
}
