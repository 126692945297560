<template>
  <Fragment>
    <table class="table card col-12">
      <tbody class="col-12">
        <tr :key="item.id" v-for="item in info">
          <td class="col-4" style="text-align: left">{{ item.name }}:</td>
          <td class="col-8" style="text-align: left">{{ item.value }}</td>
          <td class="col-1">
            <a class="cursor-pointer" @click="remove(item)">
              <i class="fa-lg icon-close text-danger"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
  name: 'ExtraInformationTable',

  data: () => ({}),
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  components: {
    Fragment,
  },
  mounted() {},
  methods: {
    remove(item) {
      this.$emit('remove-extra-item', item);
    },
  },
};
</script>
