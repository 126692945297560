<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-w-full col-lg-10 col-xl-8">
        <div class="card mt-5">
          <div class="d-flex">
            <h3 class="font-weight-light pull-left ml-3">
              Confira os dados da cobrança
            </h3>
            <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
              <a href="#" @click="$router.go(-1)">
                <i class="icon-arrow-left-circle"></i> Voltar
              </a>
            </WhiteLabel>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <div class="ted-container">
      <div v-if="pixUser" class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small icon-user gray" />
          </div>
          <div class="form-group col-11">
            <strong>Recebedor</strong>
          </div>
        </div>

        <div class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            {{ pixUser.name }}
          </div>
        </div>

        <div class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            {{ pixUser.taxDocument }}
          </div>
        </div>

        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small icon-wallet gray" />
          </div>
          <div class="form-group col-11">
            <label class="small-price-label--inline">
              <strong>Agência:</strong>
            </label>
            {{ pixUser.account.branch }}
          </div>
        </div>
        <div class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">
              <strong>
                Conta
                {{ getAccountType(pixUser.account.type) }}:
              </strong>
            </label>
            {{ pixUser.account.number }}
          </div>
        </div>
        <div class="d-flex form-group">
          <div class="form-group offset-1 col-11">
            <label class="small-price-label--inline">{{
              pixUser.account.institution
            }}</label>
          </div>
        </div>
      </div>

      <div v-if="pixData.description" class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small icon-info gray" />
          </div>
          <div class="form-group col-11">
            {{ pixData.description }}
          </div>
        </div>
      </div>

      <div v-if="pixData.amount" class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small fa fa-money gray" />
          </div>
          <div class="form-group col-11">
            {{
              pixData.amount
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </div>
        </div>
      </div>

      <div v-else class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small fa fa-money gray" />
          </div>
          <div class="form-group col-11">A definir</div>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex form-group">
          <div class="form-group col-1">
            <i class="icon-small fa fa-key gray" />
          </div>
          <div class="form-group col-11">
            {{ pixData.key }}
          </div>
        </div>
      </div>

      <WhiteLabel class="mb-4 offset-lg-6 col-12 col-lg-6 col-w-full">
        <button @click="nextSection()" class="btn btn-md btn-orange col-12">
          Gerar QR Code
        </button>
      </WhiteLabel>
    </div>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'PixResumeForm',

  components: {
    WhiteLabel,
  },

  props: {
    pixData: {
      type: Object,
      required: true,
      default: () => {
        return {
          key: '',
          amount: '',
          description: '',
        };
      },
    },
    pixUser: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: 'Pedro Munhoz',
          taxDocument: '965.845.359-56',
          institution: 'Banco do Brasil',
        };
      },
    },
  },

  methods: {
    getAccountType(accountType) {
      switch (accountType) {
        case 'checking':
          return 'corrente';
        case 'savings':
          return 'poupança';
        case 'salary':
          return 'salário';

        default:
          break;
      }
    },

    nextSection() {
      this.$emit('next-section');
    },
  },
};
</script>

<style lang="scss" scoped>
.ted-container {
  padding: 1rem;
}
.input-money {
  font-size: 48px;
  max-width: 100%;
  border: none;
  outline: none;
  font-weight: lighter;
  border-bottom: solid thin;
  border-color: #a1a4b1;
}
.mt-lg {
  margin-top: 80px;
}
.small-label {
  display: block;
  text-transform: uppercase;
  color: #7d8287;
  font-size: 12px;
}
.price-label {
  display: block;
  font-size: 30px;
  color: #7d8287;
}
.small-price-label {
  display: block;
  color: #7d8287;

  &--inline {
    display: inline;
  }
}
.icon-small {
  width: 20px;
  height: 20px;
}
</style>
