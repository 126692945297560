import CustomApiError from './CustomError';

export default class PixDeletionError extends CustomApiError {
  toDictionary() {
    return {
      PIX_KEY_NOT_FOUND: 'Chave Pix não encontrada.',
      DELETE_PIX_KEY_FAIL: 'Falha na remoção da chave.',
      AUTH_CODE_NOT_FOUND: 'Código de autenticação não encontrado.',
      UNAUTHORIZED_DEVICE: 'Dispositivo não autorizado.',
    };
  }
}
