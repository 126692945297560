<template>
  <div class="inline-flex">
    <section v-if="view === 'icon' || view === 'default'">
      <em class="icon bankslip-icon gray" v-if="type === 'bankSlip'" />
      <em
        class="icon bankslip-icon gray"
        v-if="type === 'bankSlipCommission'"
      />
      <em class="icon tax-icon gray" v-else-if="type === 'bankSlipFee'" />
      <em class="icon generic-card-icon gray" v-else-if="type === 'card' || type === 'revertedChargeback'" />
      <em class="icon tax-icon gray" v-else-if="type === 'cardFee'" />
      <em class="icon tax-icon gray" v-else-if="type === 'cardCommission'" />
      <em class="icon transfer gray" v-else-if="type === 'otherTransfer'" />
      <em class="icon billing-icon gray" v-else-if="type === 'billing'" />
      <em class="icon generic-card-icon gray" v-else-if="type === 'canceled'" />
      <em
        class="icon generic-card-icon gray"
        v-else-if="type === 'contested'"
      />
      <em class="icon transfer gray" v-else-if="type === 'internalTransfer'" />
      <em class="icon transfer gray" v-else-if="type === 'returnTransfer'" />
      <em
        class="icon tax-icon gray"
        v-else-if="type === 'returnBankTransferFee'"
      />
      <em class="icon tax-icon gray" v-else-if="type === 'bankTransferFee'" />
      <em class="icon transfer gray" v-else-if="type === 'transfer'" />
      <em
        class="icon transfer-programmed-new-icon gray"
        v-else-if="type === 'automaticTransfer'"
      />
      <em class="icon tax-icon gray mr-3" v-else-if="type === 'transferFee'" />
      <em
        class="icon generic-card-icon gray mr-3"
        v-else-if="type === 'chargeback'"
      />
      <em class="icon bankslip-icon gray" v-else-if="type === 'billPayment'" />
      <em class="icon bankslip-icon gray" v-else-if="type === 'billPaymentReturn'" />
      <em class="icon tax-icon gray" v-else-if="type === 'billPaymentFee'" />
      <em class="icon money-icon gray" v-else-if="type === 'commission'" />
      <em class="icon transfer gray" v-else-if="type === 'pixOrderDebit'" />
      <em
        class="icon transfer gray"
        v-else-if="type === 'pixOrderDebitReturn'"
      />
      <em class="icon transfer gray" v-else-if="type === 'pixOrderCredit'" />
      <em class="icon tax-icon gray" v-else-if="type === 'pixFee'" />
      <em class="icon tax-icon gray" v-else-if="type === 'pixFeeReturn'" />
      <em class="icon tax-icon gray" v-else-if="type === 'liquidatingBankTransfer'" />
    </section>

    <span class="pl-2" v-if="count">{{ count }} </span>

    <section v-if="view === 'description' || view === 'default'" class="pl-2">
      <span v-if="type === 'bankSlip'">
        &nbsp;Boleto{{ count > 1 ? 's' : '' }}
      </span>
      <span v-else-if="type === 'bankSlipFee'">
        &nbsp; Taxa{{ count > 1 ? 's' : '' }} de boleto
      </span>
      <span v-else-if="type === 'bankSlipCommission'">
        &nbsp; Comiss{{ count > 1 ? 'ões' : 'ão' }} de venda em boleto
      </span>
      <span v-else-if="type === 'card'">
        &nbsp;Cart{{ count > 1 ? 'ões' : 'ão' }}
      </span>
      <span v-else-if="type === 'cardFee'">
        &nbsp; Taxa{{ count > 1 ? 's' : '' }} de cartão
      </span>
      <span v-else-if="type === 'cardCommission'">
        &nbsp;Comiss{{ count > 1 ? 'ões' : 'ão' }} de venda em cartão
      </span>
      <span v-else-if="type === 'otherTransfer'">
        &nbsp;Outro{{ count > 1 ? 's' : '' }} Lançamento{{
          count > 1 ? 's' : ''
        }}
      </span>
      <span v-else-if="type === 'billing'">
        &nbsp;Link{{ count > 1 ? 's' : '' }} de cobrança
      </span>
      <span v-else-if="type === 'canceled'">
        &nbsp;Valor{{ count > 1 ? 'es' : '' }} cancelado{{
          count > 1 ? 's' : ''
        }}
      </span>
      <span v-else-if="type === 'contested'">
        &nbsp;Transaç{{ count > 1 ? 'ões' : 'ão' }} contestada{{
          count > 1 ? 's' : ''
        }}
      </span>
      <span v-else-if="type === 'internalTransfer'">
        &nbsp;Transferência{{ count > 1 ? 's' : '' }} Interna{{
          count > 1 ? 's' : ''
        }}
      </span>
      <span v-else-if="type === 'bankTransferFee'">
        &nbsp; Taxa{{ count > 1 ? 's' : '' }} de transferência{{
          count > 1 ? 's' : ''
        }}
        bancária{{ count > 1 ? 's' : '' }}
      </span>
      <span v-else-if="type === 'transfer'">
        &nbsp;Transferência{{ count > 1 ? 's' : '' }} manua{{
          count > 1 ? 'is' : 'l'
        }}
        para conta bancária
      </span>
      <span v-else-if="type === 'returnTransfer'">
        &nbsp;Devoluç{{ count > 1 ? 'ões' : 'ão' }} de transferência
      </span>
      <span v-else-if="type === 'returnBankTransferFee'">
        &nbsp;Devoluç{{ count > 1 ? 'ões' : 'ão' }} de taxa de transferência
      </span>
      <span v-else-if="type === 'automaticTransfer'">
        &nbsp;Transferência{{ count > 1 ? 's' : '' }} automática{{
          count > 1 ? 's' : ''
        }}
        para conta bancária
      </span>
      <span v-else-if="type === 'transferFee'">
        &nbsp;Taxa de transferência{{ count > 1 ? 's' : '' }}
      </span>
      <span v-else-if="type === 'chargeback'">
        &nbsp;Estorno{{ count > 1 ? 's' : '' }}
      </span>
      <span v-else-if="type === 'revertedChargeback'">
        &nbsp;Devoluç{{ count > 1 ? 'ões' : 'ão' }} de estorno{{ count > 1 ? 's' : '' }}
      </span>
      <span v-else-if="type === 'billPayment'">
        &nbsp;Pagamento{{ count > 1 ? 's' : '' }} de conta{{
          count > 1 ? 's' : ''
        }}
      </span>
      <span v-else-if="type === 'billPaymentReturn'">
        &nbsp;Estorno{{ count > 1 ? 's' : '' }} de pagamento{{
          count > 1 ? 's' : ''
        }} de conta{{ count > 1 ? 's' : '' }}
      </span>
      <span v-else-if="type === 'billPaymentFee'">
        &nbsp;Taxa{{ count > 1 ? 's' : '' }} de pagamento{{
          count > 1 ? 's' : ''
        }}
        de conta{{ count > 1 ? 's' : '' }}
      </span>
      <span v-else-if="type === 'commission'">
        &nbsp;Comiss{{ count > 1 ? 'ões' : 'ão' }}
      </span>
      <span v-else-if="type === 'pixOrderDebit'">
        &nbsp;Transferência{{ count > 1 ? 's' : '' }} Pix enviada{{
          count > 1 ? 's' : ''
        }}
      </span>
      <span v-else-if="type === 'pixOrderDebitReturn'">
        &nbsp;Devoluç{{ count > 1 ? 'ões' : 'ão' }} de transferência Pix
      </span>
      <span v-else-if="type === 'pixOrderCredit'">
        &nbsp;Transferência{{ count > 1 ? `s` : '' }} Pix recebida{{
          count > 1 ? 's' : ''
        }}
      </span>
      <span v-else-if="type === 'pixFee'">
        &nbsp;Taxa{{ count > 1 ? 's' : '' }} de transferência{{
          count > 1 ? 's' : ''
        }}
        Pix
      </span>
      <span v-else-if="type === 'pixFeeReturn'">
        &nbsp;Devoluç{{ count > 1 ? 'ões' : 'ão' }} de taxa de transferência Pix
      </span>
      <span v-else-if="type === 'liquidatingBankTransfer'">
        &nbsp;Crédito{{ count > 1 ? 's' : '' }} TED
      </span>
    </section>
  </div>
</template>
<script>
export default {
  name: 'AppIconHistoric',
  props: {
    type: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: 0,
    },
    view: {
      type: String,
      default: 'default',
    },
  },
};
</script>
