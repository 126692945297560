<template>
  <div class="container mt-5">
    <div class="row justify-content-around">
      <div class="container col-6 col-lg-6 col-md-6 p-3">
        <div class="card shadow-sm rounded">
          <div class="card-body container">
            <div class="row justify-content-center mt-2">
              <h3>Limites Boleto</h3>
            </div>
            <div class="row mt-4">
              <div class="col-12 mb-2">
                <h4>Máximo</h4>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-6 mb-2">
                <label>Valor máximo</label>
                <money
                  class="form-control input-value input-money"
                  :decimal="','"
                  :thousands="'.'"
                  :precision="2"
                  v-model="maximumAmount"
                  :disabled="true"
                ></money>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-6 mb-2">
                <label>Quantidade máxima</label>
                <input
                  class="form-control input-value input-money"
                  type="number"
                  min="1"
                  v-model="maximumQuantity"
                  :disabled="true"
                />
              </div>
            </div>
            <hr class="header-box" />
            <div class="row mt-4">
              <div class="col-12 mb-2">
                <h4>Atual</h4>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-8 mb-4">
                <label>Valor atual</label>
                <money
                  class="form-control input-value input-money"
                  :decimal="','"
                  :thousands="'.'"
                  :precision="2"
                  v-model="currentAmount"
                  :disabled="isDisabled ? true : false"
                ></money>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-8 mb-4">
                <label>Quantidade atual</label>
                <input
                  class="form-control input-value input-money"
                  type="number"
                  min="1"
                  :disabled="isDisabled ? true : false"
                  v-model="currentQuantity"
                />
              </div>
            </div>

            <div class="row justify-content-center mt-4">
              <div
                class="col-6 col-lg-6 col-md-6"
                @click.prevent="isDisabled = !isDisabled"
                v-if="isDisabled"
              >
                <button class="btn btn-md btn-orange col-12">
                  <i class="fa-solid fa-pen-to-square"></i> Editar Limites
                </button>
              </div>

              <div
                class="col-6 col-lg-6 col-md-6"
                @click.prevent="isDisabled = !isDisabled"
                v-if="!isDisabled"
              >
                <button
                  class="btn btn-md btn-orange col-12"
                  @click.prevent="ChangeLimit()"
                >
                  <i class="fa-solid fa-floppy-disk"></i> Salvar Limites
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentsApi from '@/services/v2/PaymentsApi';

export default {
  async mounted() {
    await this.refreshLimits();
  },
  data() {
    return {
      isDisabled: true,
      maximumAmount: 0.0,
      maximumQuantity: 0,
      currentAmount: 0.0,
      currentQuantity: 0,
    };
  },

  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
  },

  methods: {
    async refreshLimits() {
      this.$bus.$emit('spinner-run');
      let paymentsApi = new PaymentsApi();
      var response = await paymentsApi.queryBankSlipLimits();
      this.$bus.$emit('spinner-stop');
      this.fillLimitObjects(response.data);
    },

    fillLimitObjects(limit) {
      this.maximumAmount =
        limit.maximumAmount == null ? this.maximumAmount : limit.maximumAmount;
      this.maximumQuantity =
        limit.maximumQuantity == null
          ? this.maximumQuantity
          : limit.maximumQuantity;
      this.currentAmount =
        limit.currentAmount == null ? this.currentAmount : limit.currentAmount;
      this.currentQuantity =
        limit.currentQuantity == null
          ? this.currentQuantity
          : limit.currentQuantity;
    },

    async ChangeLimit() {
      this.$bus.$emit('spinner-run');

      const model = {
        amount: this.currentAmount,
        quantity: this.currentQuantity,
      };

      let paymentsApi = new PaymentsApi();
      var response = await paymentsApi.updateCurrentLimit(model);

      if (response.status == 200) {
        this.$alert.info('Alterado com sucesso.');
        this.$bus.$emit('spinner-stop');
      } else if (response.status == 422) {
        this.$bus.$emit('spinner-stop');
        this.$alert.error('Ops', response.data.errors[0]);
      } else {
        this.$bus.$emit('spinner-stop');
        this.$alert.error('Ops', 'Falha no ajuste de limites.');
      }
    },
  },
};
</script>

<style scoped>
.card {
  border: 2px solid #9d98988c;
}

.header-box {
  border: solid 0 #eeeef1;
  border-bottom-width: 0.0625rem;
}

.input-money {
  border: solid 1px #6b6969;
}

.btn-orange {
  color: #ffffff;
  background: linear-gradient(
    147.43deg,
    #ff7606 20.2%,
    #fa3645 100%
  ) !important;
}
</style>
