<template>
  <div class="form-group col-12 col-w-full">
    <form action="#" @submit.prevent="setDescription()">
      <div class="col-12 col-w-full mb-4">
        <textarea
          v-model="description"
          autofocus
          class="input-area col-12 col-w-full"
          rows="4"
          :maxlength="short ? 50 : 100"
        />
      </div>
      <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
        <button type="submit" class="btn btn-md btn-orange col-12">
          Continuar
        </button>
      </WhiteLabel>
    </form>
  </div>
</template>

<script>
// component imports
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'SetOrderDescriptionModalSection',

  components: {
    WhiteLabel,
  },

  props: {
    preLoadedDescription: {
      type: String,
      required: false,
      default: '',
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    description: null,
  }),

  mounted() {
    if (this.preLoadedDescription) this.description = this.preLoadedDescription;
  },

  methods: {
    setDescription() {
      this.$emit('set-description', this.description);
    },
  },
};
</script>
