<template>
  <div class="ted-container">
    <div class="mb-4">
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11 long-word">
          <p>
            Quem for te pagar precisa escanear esse QR Code no app que fará a
            transferência
          </p>
          <img v-auth-image="qrCodeImage" class="col-10" />
        </div>
      </div>
    </div>

    <div class="mb-4">
      <div class="d-flex form-group">
        <div class="form-group offset-1 col-11 long-word">
          Se não conseguir mostrar o QR Code, compartilhe o link para receber
          <WhiteLabel class="mt-4 mb-4">
            <a href="#" @click.prevent="copyToClipboard()">
              <i class="icon-small fa fa-copy gray" />
              Link para compartilhamento
            </a>
          </WhiteLabel>
          <input type="hidden" id="qr-code-value" :value="qrCodeValue" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueAuthImage from 'vue-auth-image';
import WhiteLabel from '@/components/shared/WhiteLabel';

// register vue-auth-image directive
Vue.use(VueAuthImage);

// component imports

export default {
  name: 'ShowPixBillModalSection',

  components: {
    WhiteLabel,
  },

  props: {
    qrCodeValue: {
      type: String,
      required: true,
      default: '',
    },
    qrCodeImage: {
      type: String,
      required: true,
      default: '',
    },
  },

  computed: {
    getQrCodeUrl() {
      return window.URL.createObjectURL(this.qrCodeImage);
    },
  },

  methods: {
    copyToClipboard() {
      let codeToCopy = document.querySelector('#qr-code-value');
      codeToCopy.setAttribute('type', 'text');
      codeToCopy.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        successful
          ? this.$alert.info(
            'Pronto',
            'Link copiado para área de transferência!'
          )
          : this.$alert.error('Ops', 'Não foi possível copiar o código');
      } catch (err) {
        this.$alert.error('Ops', 'Não foi possível copiar o código');
      }

      /* unselect the range */
      codeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style>
.long-word {
  word-wrap: break-word;
}
</style>
