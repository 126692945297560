<template>
  <div class="col-12 col-w-full mb-4">
    <div
      v-for="pixKey in pixKeys"
      :key="pixKey.key"
      class="material-radio mb-4"
    >
      <WhiteLabel>
        <a
          href="#"
          class="radio-selector d-flex mb-1"
          @click="selectKey(pixKey)"
        >
          <i :class="`${getIcon(pixKey.type)} fa-lg mr-3`" aria-hidden="true" />
          <h4 class="radio-selector form-check-label lbl-filter">
            {{ pixKey.key }}
          </h4>
        </a>
      </WhiteLabel>
    </div>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'SelectPixKeyModalSection',

  components: {
    WhiteLabel,
  },

  props: {
    pixKeys: {
      type: Array,
      required: true,
      default: () => {
        return [
          {
            type: 'email',
            key: 'schiavonjpaulo@gmail.com',
            createdAt: '2020-11-18T16:18:29.5881341',
            validatedIn: '2020-11-18T19:18:29.255',
            inactivatedOn: null,
            active: true,
          },
          {
            type: 'aleatory',
            key: 'b062a2a9-c2b6-47d2-b615-a2477781e052',
            createdAt: '2020-11-18T17:19:57.2648229',
            validatedIn: '2020-11-18T20:19:57.041',
            inactivatedOn: null,
            active: true,
          },
        ];
      },
    },
  },
  methods: {
    getIcon(type) {
      switch (type) {
      case 'cnpj':
        return 'fa fa-id-card-o';

      case 'cpf':
        return 'fa fa-id-card-o';

      case 'mobile':
        return 'fa fa-phone';

      case 'email':
        return 'fa fa-envelope';

      case 'aleatory':
        return 'fa fa-key';

      default:
        break;
      }
    },

    selectKey(key) {
      this.$emit('select-key', key);
    },
  },
};
</script>

<style>
</style>
