<template>
  <div class="form-group col-12 col-w-full">
    <div class="col-12 col-w-full col-lg-10 mb-4">
      <p>Entre o nome ou código da instituição</p>
      <div class="input-group mb-1">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i :class="`${icon} fa-md`" aria-hidden="true" />
          </span>
        </div>
        <input
          v-model="institutionsSearchParam"
          required
          type="text"
          class="form-control"
          placeholder="Buscar"
        />
      </div>
    </div>
    <div
      v-for="(institution, index) in filteredInstitutions"
      :key="index"
      class="material-radio mb-4"
    >
      <WhiteLabel>
        <a
          href="#"
          class="radio-selector d-flex mb-1"
          @click="selecInstitution(institution)"
        >
          <i :class="`${icon} fa-lg mr-3`" aria-hidden="true" />
          <h5 class="radio-selector form-check-label lbl-filter">
            {{ institution.label }}
          </h5>
        </a>
      </WhiteLabel>
    </div>
  </div>
</template>

<script>
// api imports
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';

import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'SelectBankAccountInstitution',

  components: {
    WhiteLabel,
  },

  props: {
    icon: {
      type: String,
      required: true,
      default: 'fa fa-university',
    },
  },

  data: () => ({
    listPixInstitutions: [],
    lastFilteredList: [],
    institutionsSearchParam: '',
    lastLength: 0,
  }),

  computed: {
    allPixInstitutions() {
      return this.listPixInstitutions;
    },
    filteredInstitutions() {
      if (this.institutionsSearchParam.length < 3) return [];

      let list = null;

      if (this.lastLength < this.institutionsSearchParam.length) {
        list = this.lastFilteredList.filter((x) => {
          return (
            x.label
              .toLowerCase()
              .search(this.institutionsSearchParam.toLowerCase()) !== -1
          );
        });
      } else {
        list = this.listPixInstitutions.filter((x) => {
          return (
            x.label
              .toLowerCase()
              .search(this.institutionsSearchParam.toLowerCase()) !== -1
          );
        });
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.lastFilteredList = list;

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.lastLength = this.institutionsSearchParam.length;

      return list;
    },
  },

  mounted() {
    // eslint-disable-next-line no-labels
    noInstitutionFound: null;
    this.searchPixInstitutions();
  },

  methods: {
    async searchPixInstitutions() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();

      const result = await api.pix.getAllPixInstitutions();

      if (result.status == 200) {
        if (result.data.institutions.length) {
          this.lastFilteredList = this.listPixInstitutions =
            result.data.institutions.map((oldInstitution) => {
              let newInstitution = { ...oldInstitution };
              if (oldInstitution.number)
                newInstitution.label = `(${oldInstitution.number}) ${oldInstitution.name}`;
              else newInstitution.label = oldInstitution.name;
              return newInstitution;
            });
        }
      }
      this.$bus.$emit('spinner-stop');
    },

    selecInstitution(institution) {
      this.$emit('select-institution', institution);
    },
  },
};
</script>

<style>
</style>
