import { withParams } from 'vuelidate/lib';

const phone = withParams({ type: 'phone' }, value => {
  if (!value) return true;

  var regex = new RegExp('^\\([1-9]{2}\\) (?:[2-8][0-9]|9[1-9])[0-9]{2,3}\\-[0-9]{4}$');
  return regex.test(value);
});

export { phone };
