import CustomApiError from './CustomError';

export default class PixKeyOwnerDetailsError extends CustomApiError {
  toDictionary() {
    return {
      PIX_KEY_INVALID: 'Chave Pix inválida.',
      PIX_KEY_NOT_FOUND: 'Chave Pix não encontrada.',
    };
  }
}
