import axios from 'axios';

const API_URL = 'https://viacep.com.br/ws';

const customAxios = axios.create({
  headers: {},
});

export default class ZipCodeApi {
  async searchByZipCode(zipCode) {
    try {
      const response = await customAxios.get(`${API_URL}/${zipCode}/json/`);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async searchByAddress(state, city, street) {
    try {
      street = street.replace('/ /g', '+');
      const response = await customAxios.get(`${API_URL}/${state}/${city}/${street}/json/`);
      return response.data;
    } catch (error) {
      return null;
    }
  }
}
