<template>
  <div class="container">
    <StaticPix
      v-if="pixType === 'staticPix'"
      @set-pix-data="setPixData($event)"
      :pixData="model"
    />

    <InstantPix
      v-if="pixType === 'instantPix' || pixType === 'dueDatePix'"
      :hasExpirationDate="hasExpirationDate"
      @set-pix-data="setPixData($event)"
      :pixData="model"
    />

    <div v-if="page.id !== ''" class="card mt-5">
      <div class="d-flex">
        <h3 class="font-weight-light pull-left ml-3">{{ page.title }}</h3>
        <WhiteLabel
          v-if="page.id !== 'showQrCode'"
          class="ml-auto mt-1 pull-right mr-4"
        >
          <a href="#" @click="goBack()">
            <i class="icon-arrow-left-circle"></i> Voltar
          </a>
        </WhiteLabel>
      </div>
      <hr />
      <ConfirmPixBillingModalSection
        v-if="page.id === sections.confirmCreation.id"
        :pixData="model"
        :pixUser="getUser"
        @next-section="create()"
      />
      <div
        v-if="page.id === sections.showQrCode.id"
        class="form-group col-sm-6 col-lg-5 col-w-full col-md-8 mb-4"
      >
        <ShowPixBillModalSection
          :qrCodeValue="qrCodeValue"
          :qrCodeImage="qrCodeImage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import StaticPix from '@/components/pix/transactions/bill/pix-forms/StaticPix.vue';
import InstantPix from '@/components/pix/transactions/bill/pix-forms/InstantPix.vue';

import ConfirmPixBillingModalSection from '@/components/pix/transactions/bill/ConfirmPixBillingModalSection.vue';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import ShowPixBillModalSection from '@/components/pix/transactions/bill/ShowPixBillModalSection.vue';
import PixOrderPaymentError from '@/errors/PixOrderPaymentError';

export default {
  name: 'PixForm',

  data: () => ({
    pixType: '',
    pixTypeOld: '',
    amount: '',
    model: {},
    hasExpirationDate: false,
    page: {
      id: '',
      title: '',
    },
    sections: {
      setAmount: {
        title: 'Qual valor deseja receber?',
        id: 'setAmount',
      },
      selectKey: {
        title: 'Através de qual das suas chaves deseja receber?',
        id: 'selectKey',
      },
      confirmCreation: {
        title: 'Confira os dados da cobrança',
        id: 'confirmCreation',
      },
      showQrCode: {
        title: 'Mostre o QR Code ou compartilhe o link',
        id: 'showQrCode',
      },
    },
    modalPixKeys: 'modalPixKeys',
    qrCodeValue: '',
    qrCodeImage: '',
  }),
  components: {
    WhiteLabel,
    ConfirmPixBillingModalSection,
    StaticPix,
    InstantPix,
    ShowPixBillModalSection,
  },
  computed: {
    getUser() {
      return {
        name: this.$route.params.balance.name,
        taxDocument: this.$route.params.balance.taxDocumentMask,
        account: {
          branch: this.$route.params.balance.accountBranch,
          type: this.$route.params.balance.accountType,
          number: this.$route.params.balance.accountNumber,
          institution: 'PAGGCERTO SOLUCOES EM PAGAMENTO S.A.',
        },
      };
    },
  },

  created() {
    this.pixType = this.$route.params.pixType;
    this.pixTypeOld = this.pixType;
    this.hasExpirationDate = this.$route.params.hasExpirationDate;
  },

  methods: {
    reset() {
      this.page.id = '';
      this.pixType = this.$route.params.pixType;
    },
    setPage(section) {
      this.page.id = section.id;
      this.page.title = section.title;
    },

    async create() {
      const api = new PaymentAccountsApi();

      this.$bus.$emit('spinner-run');

      let data = null;
      //  check pix type ex: 'static' or 'instantPix/dueDatePix'
      if (this.$route.params.pixType === 'staticPix') {
        data = await api.pix.createStaticQrCode(this.model);
      } else {
        data = await api.pix.createDynamicQrCode(this.model);
      }

      const result = data;

      if (result.status == 200) {
        if (result.data.confirmed) {
          this.$alert.info('Pronto', 'Cobrança via pix criada!');
          this.qrCodeValue = result.data.qrcodeValue;
          this.qrCodeImage = api.pix.mountQrCodeImageUrl(result.data.id);

          this.setPage(this.sections.showQrCode);
        } else if (result.data.error) {
          this.$alert.error('Ops', result.data.error.message);
        } else {
          this.$alert.error('Ops', 'Algo deu errado');
        }
      } else if (result.status == 422) {
        const modelError = new PixOrderPaymentError({
          error: result.data.error,
        });
        this.$alert.error('Ops', modelError.getMessage());
        this.reset();
      } else {
        this.$alert.error('Ops', 'Algo deu errado!');
        this.reset();
      }

      this.$bus.$emit('spinner-stop');
    },

    setPixData(model) {
      this.model = model;
      this.pixType = '';
      this.setPage(this.sections.confirmCreation);
    },
    goBack() {
      this.pixType = this.pixTypeOld;
      this.page.id = '';
    },
  },
};
</script>
