import { withParams } from 'vuelidate/lib';
import { noAccents } from '@/functions/noAccents';

const onlyLetters = withParams({ type: 'onlyLetters' }, value => {
  var str = noAccents(value);
  if (!str) return true;
  var regex = new RegExp("^[a-zA-Z]+[a-zA-Z'.\\s]*$");
  return regex.test(str);
});

export { onlyLetters };
