import { onlyLetters } from '@/validations/onlyLetters';
import { cpfCnpj } from '@/validations/cpfCnpj';
import { required, minLength, maxLength, maxValue, minValue, email } from 'vuelidate/lib/validators';
import { onlyNumbers } from '@/validations/onlyNumbers';
import { alphaNumeric } from '@/validations/alphaNumeric';
import { alphaNumericAddress } from '@/validations/alphaNumericAddress';
import { minCurrentDate } from '@/validations/minCurrentDate';

export default function DynamicPixValidator() {
  return {
    amount: {
      minValue: minValue(0.01),
    },
    state: {
      required,
    },
    info: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      value: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(150),
      },
    },
    payer: {
      name: {
        required,
        onlyLetters,
        minLength: minLength(2),
      },
      taxDocument: {
        required,
        cpfCnpj,
      },
      email: {
        required,
        email,
      },
      address: {
        cityCode: {
          required,
          onlyNumbers,
          maxLength: maxLength(10),
        },
        district: {
          required,
          onlyLetters,
        },
        line1: {
          required,
          alphaNumericAddress,
        },
        line2: {
          alphaNumericAddress,
        },
        streetNumber: {
          required,
          alphaNumeric,
        },
        zipCode: {
          required,
          minLength: minLength(9),
        },
      },
    },
    model: {
      dueDate: {
        minCurrentDate,
      },
      expiredInDays: {
        maxValue: maxValue(120),
        minValue: minValue(0),
        required,
      },
      dueDateAfterDays: {
        maxValue: maxValue(30),
        minValue: minValue(0),
      },
    },
  };
}
