<template>
  <div class="card-transference">
    <div class="row">
      <h1 class="date">{{ content.day | moment("dddd, DD/MM/YY") }}</h1>
    </div>
    <div v-for="(order, index) in content.orders" :key="index">
      <a
        :data-toggle="'modal'"
        :data-target="'#' + detailsModal"
        class="line"
        @click="openModal(order)"
      >
        <em class="icon transfer gray" />
        <div class="content-card-transference">
          <h1 class="title-transference mt-2 pl-4">
            {{ getOrderNote(order) }}
          </h1>
          <div class="status-transference">
            <h1>
              {{ order.id }} &nbsp; - &nbsp;
              <b :class="getStatusColor(order.status, false)">{{
                getStatusName(order.status)
              }}</b>
            </h1>
          </div>
          <div :class="getStatusColor(order.status)">
            <p>{{ getStatusName(order.status, order.type) }}</p>
          </div>
        </div>
        <div
          class="value mt-1"
          :class="{
            tachado: order.status === 'rejected' || order.status === 'error',
          }"
        >
          {{
            order.amount
              | currency("R$ ", 2, {
                decimalSeparator: ",",
                thousandsSeparator: ".",
              })
          }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPixTransactionsContent',

  props: {
    content: {
      type: Object,
      required: true,
      default: () => {
        return {
          day: new Date(),
          orders: [],
        };
      },
    },
    detailsModal: {
      type: String,
      required: true,
      default: '',
    },
  },

  methods: {
    getStatusColor(status, web = true) {
      if (web) {
        return {
          'status-web-approved': status === 'successful',
          'status-web-reproved': status === 'error',
          'status-web-reproved': status === 'rejected',
          'status-web-pending': status === 'submitted',
          'status-web-pending': status === 'devolution',
        };
      } else {
        return {
          'status-approved': status === 'successful',
          'status-reproved': status === 'error',
          'status-reproved': status === 'rejected',
          'status-pending': status === 'submitted',
          'status-pending': status === 'devolution',
        };
      }
    },

    getStatusName(status, type = null) {
      if (type === 'pixOrderCredit' && status === 'successful')
        return 'Recebida';

      switch (status) {
      case 'devolution':
        return 'Devolvido';
      case 'submitted':
        return 'Enviada';
      case 'successful':
        return 'Paga';
      case 'rejected':
        return 'Reprovada';
      case 'error':
        return 'Erro';
      default:
        break;
      }
    },

    getOrderNote(order) {
      let returnStr = `#${order.id} Transferência ${
        order.type === 'pixOrderDebit' ? 'enviada' : 'recebida'
      }`;

      if (order.destination) {
        if (order.destination.name) {
          returnStr += `${order.type === 'pixOrderDebit' ? ' para' : ' de'} ${
            order.destination.name
          }`;
          if (order.destination.taxDocument)
            returnStr += ` (${order.destination.taxDocument})`;
        } else if (order.destination.taxDocument) {
          returnStr += `${order.type === 'pixOrderDebit' ? ' para' : ' de'} ${
            order.destination.taxDocument
          }`;
        }
      }

      return returnStr;
    },

    openModal(order) {
      this.$emit('open-modal', order);
    },
  },
};
</script>
