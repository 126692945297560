<template>
    <Fragment>
      <table class="table card col-12">
        <tbody class="col-12">
          <tr :key="item.id" v-for="item in info">
            <td class="col-4" style="text-align: left">{{ item.name }}:</td>
            <td class="col-8" style="text-align: left">{{ item.value }}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  </template>

  <script>
  import { Fragment } from 'vue-fragment';

  export default {
    name: 'ExtraInformationTableQrCode',

    data: () => ({}),
    props: {
      info: {
        type: Array,
        required: true,
      },
    },
    components: {
      Fragment,
    },
    mounted() {},
  };
  </script>
