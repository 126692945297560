<template>
  <div class="container mt-5">
    <div class="row justify-content-around">
      <div class="container col-6 col-lg-6 col-md-6 p-3">
        <div class="card shadow-sm rounded">
          <div class="card-body container">
            <div class="row justify-content-center mt-2">
              <h3>Titularidade própria</h3>
            </div>
            <div class="row mt-4">
              <div class="col-12 mb-2">
                <h4>
                  Limite diurno
                </h4>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-6 mb-2">
                <label>Em dias úteis</label>
                <money v-model="sameHolderDailyWorkingDaysLimit" class="form-control input-value input-money"
                  :decimal="','" :thousands="'.'" :precision="2" :disabled="true"></money>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-6 mb-2">
                <label>Aos finais de semana</label>
                <money v-model="sameHolderDailyWeekendLimit" class="form-control input-value input-money" :decimal="','"
                  :thousands="'.'" :precision="2" :disabled="true"></money>
              </div>
            </div>
            <div class="header-box"></div>
            <div class="row mt-4">
              <div class="col-12 mb-2">
                <h4>
                  Limite noturno
                </h4>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-8 mb-4">
                <label>Em dias úteis</label>
                <money v-model="sameHolderNocturnalWorkingDaysLimit" class="form-control input-value input-money"
                  :decimal="','" :thousands="'.'" :precision="2" :disabled="true"></money>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-8 mb-4">
                <label>Aos finais de semana</label>
                <money v-model="sameHolderNocturnalWeekendLimit" class="form-control input-value input-money"
                  :decimal="','" :thousands="'.'" :precision="2" :disabled="true"></money>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="container col-6 col-lg-6 col-md-6 p-3">
        <div class="card shadow-sm rounded">
          <div class="card-body container">
            <div class="row justify-content-center mt-2">
              <h3>Titularidade de terceiros</h3>
            </div>
            <div class="row mt-4">
              <div class="col-12 mb-2">
                <h4>
                  Limite diurno
                </h4>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-6 mb-2">
                <label>Em dias úteis</label>
                <money v-model="dailyWorkingDaysLimit" class="form-control input-value input-money" :decimal="','"
                  :thousands="'.'" :precision="2" :disabled="true"></money>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-6 mb-2">
                <label>Aos finais de semana</label>
                <money v-model="dailyWeekendLimit" class="form-control input-value input-money" :decimal="','"
                  :thousands="'.'" :precision="2" :disabled="true"></money>
              </div>
            </div>
            <div class="header-box"></div>
            <div class="row mt-4">
              <div class="col-12 mb-2">
                <h4>
                  Limite noturno
                </h4>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-8 mb-4">
                <label>Em dias úteis</label>
                <money v-model="nocturnalWorkingDaysLimit" class="form-control input-value input-money" :decimal="','"
                  :thousands="'.'" :precision="2" :disabled="true"></money>
              </div>
              <div class="form-group col-12 col-lg-6 col-md-6 col-sm-8 mb-4">
                <label>Aos finais de semana</label>
                <money v-model="nocturnalWeekendLimit" class="form-control input-value input-money" :decimal="','"
                  :thousands="'.'" :precision="2" :disabled="true"></money>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';

export default ({
  async mounted() {
    await this.refreshLimits();
  },
  data: () => ({
    dailyWorkingDaysLimit: 0.00,
    nocturnalWorkingDaysLimit: 0.00,
    dailyWeekendLimit: 0.00,
    nocturnalWeekendLimit: 0.00,
    sameHolderDailyWorkingDaysLimit: 0.00,
    sameHolderNocturnalWorkingDaysLimit: 0.00,
    sameHolderDailyWeekendLimit: 0.00,
    sameHolderNocturnalWeekendLimit: 0.00,
  }),
  methods: {
    async refreshLimits() {
      this.sameHolderKey += 1;
      this.anotherHolderKey += 1;
      this.$bus.$emit('spinner-run');
      let paymentAccApi = new PaymentAccountsApi();
      var response = await paymentAccApi.getTransferLimits();
      this.fillLimitObjects(response.transferAmountLimits);
      this.$bus.$emit('spinner-stop');
    },

    fillLimitObjects(limits) {
      limits.forEach(limit => {
        if (limit.product == 'external-transfer') {
          if (limit.sameHolderDestination == true) {
            if (limit.workingDaysLimit != null) {
              this.sameHolderDailyWorkingDaysLimit = limit.workingDaysLimit.dailyCumulativeAmount;
              this.sameHolderNocturnalWorkingDaysLimit = limit.workingDaysLimit.nocturnalCumulativeAmount;
            }

            if (limit.weekendLimit != null) {
              this.sameHolderDailyWeekendLimit = limit.weekendLimit.dailyCumulativeAmount;
              this.sameHolderNocturnalWeekendLimit = limit.weekendLimit.nocturnalCumulativeAmount;
            }
          }

          if (limit.sameHolderDestination == false) {
            if (limit.workingDaysLimit != null) {
              this.dailyWorkingDaysLimit = limit.workingDaysLimit.dailyCumulativeAmount;
              this.nocturnalWorkingDaysLimit = limit.workingDaysLimit.nocturnalCumulativeAmount;
            }

            if (limit.weekendLimit != null) {
              this.dailyWeekendLimit = limit.weekendLimit.dailyCumulativeAmount;
              this.nocturnalWeekendLimit = limit.weekendLimit.nocturnalCumulativeAmount;
            }
          }
        }
      });
    },
  },
});

</script>

<style scoped>
.card {
  border: 2px solid;
}
</style>
