<template>
  <div class="historic">
    <div class="timelineContent">
      <ul>
        <li v-for="(line, index) in data" :key="index">
          <div class="header">
            <span>{{ getStatusLabel(line.status) }}</span>
          </div>
          <div class="body">
            <div>
              <span>{{ line.date | moment('DD/MM/YYYY HH:mm:ss') }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTimeLine',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    data: {
      required: true,
    },
  },
  data() {
    return {
      status: {
        pending: 'Pendente',
        processing: 'Em Processamento',
        provisioned: 'Provisionado',
        custodied: 'Custodiado',
        transferred: 'Transferido',
        canceled: 'Cancelado',
        reproved: 'Devolvido pelo Banco',
        approved: 'Aprovado',
        contested: 'Contestado',
        paid: 'Pago',
        availableCustody: 'Pago',
        created: 'Emitido',
        manualCanceled: 'Cancelamento manual',
        disputeSucceeded: 'Disputa vencida pelo estabelecimento',
        captured: 'Capturado',
        disputed: 'Em Disputa',
        revertedChargeback: 'Reversão da parcela estornada',
      },
    };
  },
  methods: {
    getStatusLabel(sta) {
      return this.status[sta];
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 991px) {
  .historic {
    .timelineContent {
      ul {
        padding-left: 0.6em;
        border-left: 2px solid #d1d1d1;
        font-size: 2.3rem;
        line-height: 0rem;
      }
    }
  }
}
@media (min-width: 992px) {
  .historic {
    .timelineContent {
      ul {
        padding-left: 0.6em;
        border-left: 2px solid #d1d1d1;
        font-size: 2.3rem;
        line-height: 0;
      }
    }
  }
}

.historic {
  background-color: #f2f2f2;
  width: 100%;
  padding: 1.5rem 2.5rem 1rem 2.5rem;

  .timelineContent {
    color: #d1d1d1;
    padding: 0;

    &::before {
      top: 4%;
      left: 5%;
    }

    ul {
      font-size: 2.3rem;

      li > .header {
        margin-bottom: 0;
      }

      li {
        padding-left: 1rem;
        padding-bottom: 2rem;
      }
    }

    .body > div > span {
      color: #8e8e8e;
      font-size: 10px;
      font-weight: 300;
      line-height: 12px;
    }

    .header > span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #5e5e5e;
    }
  }
}
</style>
