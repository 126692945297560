import { withParams } from 'vuelidate/lib';
import { noAccents } from '@/functions/noAccents';

const alphaNumeric = withParams({ type: 'alphanumeric' }, value => {
  var str = noAccents(value);
  if (!str) return true;
  const pattern = "^[a-zA-Z0-9'&()ºª°\\-\\/,.\\s]+$";
  var regex = new RegExp(pattern);
  return regex.test(str);
});

export { alphaNumeric };
