<template>
  <div class="container">
    <div class="col-12 col-w-full col-lg-9 col-xl-7">
      <div class="content-one mb-3">
        <div class="col-sm-6">
          <WhiteLabel>
            <a
              data-toggle="modal"
              data-target="#modal-pix-key-registration"
              class="pointer btn btn-orange btn-pill align-self"
              @click="loadSuggestions()"
            >
              <i class="icon-plus" />
              Nova chave
            </a>
          </WhiteLabel>
        </div>
      </div>
      <div v-if="keysCount > 0">
        <ContentKeys
          :keys="activeKeys"
          :contactInformation="userInformation"
          @updateKeys="getKeys()"
        />
      </div>
      <div v-if="keysCount === 0">
        <NotFoundKeys />
      </div>
    </div>
    <KeyRegistrationModal
      id="modal-pix-key-registration"
      @key-registered="getKeys()"
      @claim-requested="onClaimRequested($event)"
      ref="keyRegistrationModal"
    />
    <ModalBase
      :id="authCodeModalData.id"
      :title="'Reivindicação de posse'"
      :hasFooter="false"
      size="modal-md"
    >
      <div slot="content">
        <AuthCodeModalSection
          :ref="authCodeModalData.id"
          :subSection="authCodeModalData.subSection"
          :email="getEmailKey"
          :mobile="getMobileKey"
          :destination="this.selectedKey.key"
          :disableEmail="this.selectedKey.type === 'mobile'"
          :disableMobile="this.selectedKey.type === 'email'"
          :type="'pix-register-key'"
          message="Enviaremos um código de verificação para validarmos a posse da chave. Este código expirará em 10 minutos"
          lastButtonText="Confirmar"
          @code-ready="claimKey($event)"
          @auth-sent="authCodeModalData.subSection = 'code'"
        />
      </div>
    </ModalBase>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import ContentKeys from '@/components/pix/keys/ContentKeys';
import NotFoundKeys from '@/components/pix/keys/NotFoundKeys';
import KeyRegistrationModal from '@/components/pix/keys/KeyRegistrationModal';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';
import AuthCodeModalSection from '@/components/shared/modal/AuthCodeModalSection';
import ModalBase from '@/components/shared/ModalBase';
import swal from 'sweetalert2';
import DeviceApi from '@/services/DeviceApi';
import FingerPrintApi from '@/services/FingerPrintApi';
import { Presets } from '@/models/Presets';

export default {
  components: {
    ContentKeys,
    NotFoundKeys,
    KeyRegistrationModal,
    WhiteLabel,
    AuthCodeModalSection,
    ModalBase,
  },

  data() {
    return {
      keys: null,
      preset: new Presets(),
      deviceId: '',
      requestId: '',
      authCodeModalData: {
        id: 'authCodeModal',
        subSection: 'destination',
      },
      selectedKey: {
        key: null,
        type: null,
      },
    };
  },

  computed: {
    getEmailKey() {
      return this.selectedKey.type === 'email' ? this.selectedKey.key : null;
    },
    getMobileKey() {
      return this.selectedKey.type === 'mobile' ? this.selectedKey.key : null;
    },
    keysCount() {
      return this.keys != null ? this.keys.keys.length : 0;
    },
    activeKeys() {
      var activeKeys = this.keys.keys.filter((key) => key.active);

      return {
        keys: activeKeys,
        count: activeKeys.length,
        limit: this.keys.limit,
      };
    },
    userInformation() {
      if (this.preset != null) {
        return {
          mobile: this.preset.holder.mobile,
          email: this.preset.holder.email,
          taxDocument:
            this.preset.holder.company != null
              ? this.preset.holder.company.taxDocument
              : this.preset.holder.taxDocument,
        };
      }

      return {};
    },
  },

  async created() {
    this.$bus.$emit('spinner-run');
    await this.getKeys();
    await this.getPreset();
    await this.checkDeviceAuthorization();
    this.$bus.$emit('spinner-stop');
  },

  methods: {
    async loadSuggestions() {
      await this.$refs.keyRegistrationModal.loadSuggestionsAgain();
    },

    async checkDeviceAuthorization() {
      this.isNaturalPerson = this.preset.holder.company == null;

      if (this.isNaturalPerson) {
        const fingerPrintApi = new FingerPrintApi();
        await fingerPrintApi.verifyFingerPrintExpiration(this.preset.holder.id);
        const { fingerPrint } = JSON.parse(localStorage.getItem('fingerPrint'));
        const deviceApi = new DeviceApi();
        const response = await deviceApi.getDevice(fingerPrint.visitorId);

        if (response.status == 200 && response.data.status == 'Active') {
          this.deviceId = response.data.id;
          this.requestId = fingerPrint.requestId;
        }
      }
    },

    async getKeys() {
      var paymentAccountApi = new PaymentAccountsApi();
      var result = await paymentAccountApi.pix.getKeys();

      this.keys = result.data;

      const keyCount = result.data.keys.filter((key) => key.active).length;

      if (!keyCount) $('#modal-pix-key-registration').modal();
    },
    async getPreset() {
      var accountApi = new AccountApi();

      this.preset = await accountApi.presets();
    },
    updateKeysList(deletedkey) {
      this.keys.keys = this.keys.keys.filter((key) => key.key != deletedkey);
    },
    async onClaimRequested(payload) {
      this.selectedKey = {
        key: payload.key,
        type: payload.keyType,
      };

      if (payload.keyType === 'email' || payload.keyType === 'mobile') {
        $(`#${this.authCodeModalData.id}`).modal('show');
        return;
      }

      await this.claimKey();
    },
    async claimKey(authCode) {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      const result = await api.pix.claimKey(
        this.selectedKey,
        authCode,
        this.deviceId,
        this.requestId
      );

      this.$bus.$emit('spinner-stop');

      this.selectedKey = {
        key: null,
        type: null,
      };

      $(`#${this.authCodeModalData.id}`).modal('hide');

      let errorMessage = '';
      if (result.status == 200) {
        const response = result.data;
        if (!response.error) {
          swal
            .fire({
              title: 'Reivindicação feita com sucesso!',
              type: 'success',
              timer: 2500,
              showConfirmButton: false,
            })
            .then(() =>
              this.$router.push({
                name: 'pix-claims',
                params: { isSent: true },
              })
            );
        }

        errorMessage = response.error.message;
      }

      if (result.status == 422) {
        var authCodeError = new AuthCodeError(result.data);
        if (authCodeError) errorMessage = authCodeError.getMessage();
      }

      swal.fire({
        title: 'Falha ao tentar reivindicar posse da chave!',
        text: errorMessage,
        type: 'error',
      });
    },
  },
};
</script>
