var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table table-striped"},[_vm._m(0),_c('tbody',[(_vm.claims.length === 0)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v("Nenhum pedido encontrado")])]):_vm._l((_vm.claims),function(claim){return _c('tr',{key:claim.id},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(claim.key))]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(claim.portability ? 'Portabilidade' : 'Reivindicação')+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm._f("dateTime")(claim.createdAt)))]),(claim.status === 'pending')?_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge badge-pill badge-pending w-75 p-2 pl-2 pr-2"},[_vm._v("Conclusão pendente")])]):(claim.status === 'canceled')?_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge badge-pill badge-secondary w-75 p-2 pl-2 pr-2"},[_vm._v("Cancelado")])]):(claim.status === 'approved')?_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"badge badge-pill badge-success w-75 p-2 pl-2 pr-2"},[_vm._v("Aprovado")])]):_vm._e(),_c('td',{staticClass:"text-left"},[(claim.status === 'pending')?_c('div',{staticClass:"dropdown"},[_c('WhiteLabel',[_c('button',{staticClass:"btn btn-outline-orange btn-pill dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" Ações ")]),_c('ul',{staticClass:"dropdown-menu"},[(claim.status === 'pending' && claim.type === 'donor')?_c('Fragment',[_c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.approveClaim(claim.id)}}},[_vm._v(" Aprovar "),_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})])]),_c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.validateCancelClaim(
                        claim.id,
                        claim.keyType,
                        claim.type === 'donor',
                        claim.key
                      )}}},[_vm._v(" Recusar "),_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})])])]):(
                  claim.status === 'pending' && claim.type === 'claimer'
                )?_c('Fragment',[_c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.validateCancelClaim(
                        claim.id,
                        claim.keyType,
                        claim.type === 'donor',
                        claim.key
                      )}}},[_vm._v(" Cancelar "),_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})])])]):_vm._e()],1)])],1):_vm._e()])])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Chave")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Ocorrência")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Data solicitação")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}}),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}})])])}]

export { render, staticRenderFns }