<template>
  <Fragment>
    <div class="form-group col-w-full col-12 col-lg-5 col-md-5 col-sm-6">
      <WhiteLabel class="">
        <a
          data-toggle="modal"
          :data-target="'#' + modalPixKeys"
          class="pointer btn btn-orange btn-pill align-self btn-transference"
          >Minhas Chaves Pix *</a
        >
      </WhiteLabel>
      <small
        class="text-danger"
        style="margin-left: 1.3rem"
        v-if="showErrorKey"
      >
        Chave pix é obrigatória</small
      >
    </div>

    <div class="form-group col-w-full col-12 col-lg-5 col-md-5 col-sm-6 mb-4">
      <label>{{ model.key }}</label>
    </div>

    <div class="col-sm-12 mt-3">
      <WhiteLabel>
        <!-- Endereco Obrigatorio caso dueDate seja Preenchido -->
        <h5 class="font-weight-light blue">Dados do Pagador *</h5>
      </WhiteLabel>
    </div>

    <div class="form-group col-w-full col-12 col-lg-4 col-md-4 col-sm-8 mb-4">
      <label for="inputValor">Qual valor deseja receber?</label>
      <money
        v-model="amount"
        class="form-control col-lg-8 col-sm-7 input-value input-money"
        :decimal="','"
        :thousands="'.'"
        :precision="2"
        @input="$v.amount.$touch()"
      ></money>
      <small class="text-danger" v-if="!$v.amount.minValue && $v.amount.$dirty">
        O valor deve ser maior que zero.
      </small>
    </div>

    <div class="form-group col-w-full col-12 col-lg-4 col-md-4 col-sm-6 mb-4">
      <label for="inputNome">Nome *</label>
      <input
        type="text"
        autofocus
        class="form-control col-12 col-w-full"
        v-model="payer.name"
        :maxlength="100"
        @input="$v.payer.name.$touch()"
      />
      <small
        class="text-danger"
        v-if="
          (!$v.payer.name.required && $v.payer.name.$dirty) ||
          (showErrors && !$v.payer.name.$dirty)
        "
      >
        O nome é obrigatório!
      </small>
      <small class="text-danger" v-if="!$v.payer.name.minLength">
        O mínimo são 2 caracteres!
      </small>
      <small class="text-danger" v-if="!$v.payer.name.onlyLetters">
        O nome é inválido!
      </small>
    </div>

    <div class="form-group col-w-full col-12 col-lg-4 col-md-4 col-sm-6 mb-4">
      <label for="inputDescricao">CPF/CNPJ</label
      ><span v-if="isRequired"> *</span>
      <input
        autofocus
        class="form-control input col-12 col-w-full"
        v-model="payer.taxDocument"
        v-mask="['###.###.###-##', '##.###.###/####-##']"
        @input="$v.payer.taxDocument.$touch()"
      />
      <small class="text-danger" v-if="taxDocumentIsInvalid">
        O CPF/CNPJ é obrigatório!
      </small>
      <small
        class="text-danger"
        v-if="isRequired && !$v.payer.taxDocument.cpfCnpj"
      >
        Digite um CPF/CNPJ válido!
      </small>
    </div>

    <div class="form-group col-w-full col-12 col-lg-6 col-md-6 col-sm-6 mb-4">
      <label for="inputEmail">E-mail</label><span v-if="isRequired"> *</span>
      <input
        type="email"
        class="form-control"
        v-model="payer.email"
        placeholder="Digite aqui o e-mail."
        @input="$v.payer.email.$touch()"
      />
      <small class="text-danger" v-if="emailIsInvalid"
        >O email é obrigatório.</small
      >
      <small class="text-danger" v-if="isRequired && !$v.payer.email.email"
        >Digite um e-mail válido.</small
      >
    </div>
    <div class="form-group col-w-full col-12 col-lg-6 col-md-6 col-sm-6 mb-4">
      <label for="inputEmail">Nota</label>
      <input
        type="text"
        class="form-control"
        :maxlength="100"
        v-model="payer.note"
      />
    </div>
    <div class="col-sm-12">
      <WhiteLabel>
        <!-- Endereco Obrigatorio caso dueDate seja Preenchido -->
        <h6 class="font-weight-light blue">
          Endereço <span v-if="isRequired"> *</span>
        </h6>
      </WhiteLabel>
    </div>

    <div class="form-group col-w-full col-12 col-lg-3 col-md-3 col-sm-4 mb-4">
      <label for="inputCep">CEP</label
      ><span v-if="isRequired || isAddressRequired"> *</span>
      <input
        type="text"
        class="form-control col-lg-12"
        v-mask="['#####-###']"
        :maxlength="9"
        @change="searchZipCode(payer.address.zipCode)"
        v-model="payer.address.zipCode"
        @input="$v.payer.address.zipCode.$touch()"
        @v-on:input="clearFields()"
      />
      <small class="text-danger" v-if="cepIsInvalid">
        O CEP é obrigatório.
      </small>
      <small
        class="text-danger"
        v-if="
          (isRequired || isAddressRequired) &&
          !$v.payer.address.zipCode.minLength
        "
      >
        Formato do CEP inválido.
      </small>
    </div>

    <div class="form-group col-w-full col-12 col-lg-7 col-md-9 col-sm-8 mb-4">
      <label for="inputBairro">Endereço (Rua, Avenida, Logradouro, etc.)</label
      ><span v-if="isRequired || isAddressRequired"> *</span>
      <input
        type="text"
        class="form-control"
        maxlength="150"
        v-model="payer.address.line1"
        :disabled="isDisabledAddressLine1"
        @input="$v.payer.address.line1.$touch()"
      />
      <small class="text-danger" v-if="line1IsInvalid">
        O endereco é obrigatório.
      </small>
      <small
        class="text-danger"
        v-if="
          (isRequired || isAddressRequired) &&
          !$v.payer.address.line1.alphaNumericAddress
        "
      >
        O endereço é inválido.
      </small>
    </div>

    <div class="form-group col-w-full col-5 col-lg-2 col-md-3 col-sm-3 mb-4">
      <label for="inputNumero">Número</label
      ><span v-if="isRequired || isAddressRequired"> *</span>
      <input
        type="text"
        class="form-control"
        maxlength="20"
        v-model="payer.address.streetNumber"
        @input="$v.payer.address.streetNumber.$touch()"
      />
      <small class="text-danger" v-if="streetNumberIsInvalid">
        O número da residência é obrigatório.
      </small>
      <small
        class="text-danger"
        v-if="isRequired && !$v.payer.address.streetNumber.alphaNumeric"
      >
        Número da residência inválido.
      </small>
    </div>

    <div class="form-group col-w-full col-lg-4 col-md-9 col-sm-9 mb-4">
      <label for="inputBairro">Complemento</label>
      <input
        type="text"
        class="form-control"
        maxlength="80"
        v-model="payer.address.line2"
      />
      <small
        class="text-danger"
        v-if="
          (isRequired || isAddressRequired) &&
          !$v.payer.address.line2.alphaNumericAddress
        "
      >
        O complemento é inválido.
      </small>
    </div>

    <div class="form-group col-w-full col-12 col-lg-3 col-md-4 col-sm-7 mb-4">
      <label for="inputBairro">Bairro</label
      ><span v-if="isRequired || isAddressRequired"> *</span>
      <input
        type="text"
        class="form-control"
        maxlength="50"
        v-model="payer.address.district"
        :disabled="isDisabledDistrict"
        @input="$v.payer.address.district.$touch()"
      />
      <small class="text-danger" v-if="districtIsInvalid">
        O bairro é obrigatório.
      </small>
      <small
        class="text-danger"
        v-if="
          (isRequired || isAddressRequired) &&
          !$v.payer.address.district.onlyLetters
        "
      >
        O bairro é inválido.
      </small>
    </div>

    <div class="form-group col-w-full col-12 col-lg-2 col-md-3 col-sm-5 mb-4">
      <label for="inputEstado">Estado(UF)</label
      ><span v-if="isRequired || isAddressRequired"> *</span>
      <select
        class="form-control text-center col-lg-10"
        name="state"
        @change="searchCities(state)"
        v-model="state"
        :disabled="isDisabledState"
        @input="$v.state.$touch()"
      >
        <option value>Selecione</option>
        <option v-for="state in allStates" :key="state.id" :value="state.sigla">
          {{ state.sigla }}
        </option>
      </select>
      <small class="text-danger" v-if="stateIsInvalid">
        O estado é obrigatório.
      </small>
    </div>

    <div class="form-group col-w-full col-12 col-lg-3 col-md-5 col-sm-7 mb-4">
      <label for="inputCity">Cidade</label
      ><span v-if="isRequired || isAddressRequired"> *</span>
      <select
        class="form-control"
        name="city"
        v-model="city"
        @change="selectCity(allCities)"
        :disabled="isDisabledCity"
        @input="$v.payer.address.cityCode.$touch()"
      >
        <option value>Selecione</option>
        <option v-for="city in allCities" :key="city.id" :value="city.nome">
          {{ city.nome }}
        </option>
      </select>
      <small class="text-danger" v-if="cityIsInvalid">
        A cidade é obrigatória.
      </small>
    </div>

    <div class="col-sm-12">
      <WhiteLabel>
        <h5 class="font-weight-light blue">Informações Adicionais</h5>
      </WhiteLabel>
    </div>
    <div
      v-if="!hasExpirationDate"
      class="form-group col-w-full col-12 col-lg-4 col-md-6 col-sm-6 mb-4"
    >
      <label for="inputNumeroDias">Número de dias até a expiração</label
      ><span> *</span>
      <input
        type="text"
        :maxlength="3"
        onkeypress="if (!isNaN(String.fromCharCode(window.event.keyCode))) return true; else return false;"
        class="form-control col-lg-6 col-md-8"
        v-model="model.expiredInDays"
        @input="$v.model.expiredInDays.$touch()"
      />
      <small class="text-danger" v-if="expiredInDaysIsInvalid">
        O número de dias é obrigatório.
      </small>
      <small class="text-danger" v-if="!$v.model.expiredInDays.maxValue">
        Máximo 120 dias.
      </small>
      <small class="text-danger" v-if="!$v.model.expiredInDays.minValue">
        Mínimo de 0 dia(s).
      </small>
    </div>

    <div
      v-if="hasExpirationDate"
      class="form-group col-w-full col-12 col-lg-4 col-md-6 col-sm-6 mb-4"
    >
      <label for="inputDataVencimento">Data de Vencimento</label>
      <datepicker
        v-model="model.dueDate"
        input-class="form-control col-lg-10"
        :calendar-button="false"
        calendar-button-icon="icon-calendar"
        language="pt-br"
        format="dd/MM/yyyy"
        placeholder="00/00/0000"
      />
      <small
        class="text-danger"
        v-if="isRequired && !$v.model.dueDate.minCurrentDate"
      >
        Data de vencimento inválida.
      </small>
    </div>

    <div
      v-if="hasExpirationDate"
      class="form-group col-w-full col-6 col-lg-2 col-md-3 col-sm-6 mb-4"
    >
      <label for="inputJuros">Juros</label>
      <money
        class="form-control input-value input-money"
        v-model="model.interest"
        :decimal="','"
        :thousands="'.'"
        :precision="2"
      >
      </money>
    </div>

    <div
      v-if="hasExpirationDate"
      class="form-group col-w-full col-6 col-lg-2 col-md-3 col-sm-6 mb-4"
    >
      <label for="inputMulta">Multa</label>
      <money
        class="form-control col-lg-12 input-value input-money"
        v-model="model.fines"
        :decimal="','"
        :thousands="'.'"
        :precision="2"
      ></money>
    </div>

    <div
      v-if="hasExpirationDate"
      class="form-group col-w-full col-6 col-lg-2 col-md-3 col-sm-6 mb-4"
    >
      <label for="inputDesconto">Desconto</label>
      <money
        class="form-control col-lg-12 input-value input-money"
        v-model="model.discount"
        :decimal="','"
        :thousands="'.'"
        :precision="2"
      ></money>
    </div>

    <div
      v-if="hasExpirationDate"
      class="form-group col-w-full col-6 col-lg-2 col-md-3 col-sm-6 mb-4"
    >
      <label for="inputAbatimento">Abatimento</label>
      <money
        class="form-control col-12 input-value input-money"
        v-model="model.rebate"
        :decimal="','"
        :thousands="'.'"
        :precision="2"
      ></money>
    </div>

    <div class="form-group col-w-full col-6 col-lg-4 col-md-6 col-sm-6 mb-4">
      <label for="inputIdCobranca">ID da Cobrança</label>
      <input
        type="text"
        class="form-control col-lg-10"
        v-model="model.billingId"
      />
    </div>

    <div class="form-group col-12 col-w-full col-lg-6 mb-4">
      <WhiteLabel class="d-flex col-12 pl-0">
        <label class="radio-selector d-flex">
          <input type="checkbox" v-model="model.reusable" />
          <div class="checkbox-badge mt-1 mr-2"></div>
          <label class="mb-0 d-flex">Código QR Reutilizável</label>
        </label>
      </WhiteLabel>
    </div>
    <div
      v-if="hasExpirationDate"
      class="form-group col-w-full col-6 col-lg-4 col-md-6 col-sm-6 mb-4"
    >
      <label for="inputVencimentoAposDias"
        >Dias de Validade Após Vencimento</label
      >
      <input
        class="form-control col-lg-6 col-md-8"
        type="text"
        :maxlength="2"
        onkeypress="if (!isNaN(String.fromCharCode(window.event.keyCode))) return true; else return false;"
        v-model="model.dueDateAfterDays"
        @input="$v.model.dueDateAfterDays.$touch()"
      />
      <small class="text-danger" v-if="!$v.model.dueDateAfterDays.maxValue">
        Máximo 30 dia(s).
      </small>
      <small class="text-danger" v-if="!$v.model.dueDateAfterDays.minValue">
        Mínimo de 0 dia(s).
      </small>
    </div>

    <div class="form-group col-12 col-w-full col-lg-12 mb-12" align="center">
      <WhiteLabel>
        <h5 class="font-weight-light pull-left blue">Informações Extras</h5>
      </WhiteLabel>
      <WhiteLabel class="ml-auto mt-1 pull-right mr-4 mb-2">
        <a
          data-toggle="modal"
          :data-target="'#' + modalExtraInformation"
          class="btn btn-outline cursor-pointer"
        >
          <i class="icon-small icon-plus mr-2" color="white"></i>Adicionar</a
        >
      </WhiteLabel>

      <SelectPixModal :id="modalPixKeys" @select-key="selectKey($event)" />

      <ExtraInformationModal
        :id="modalExtraInformation"
        @add-extra-information="addExtraInformation($event)"
      />
    </div>
    <div class="form-group col-12 col-w-full col-lg-12 mb-6" align="center">
      <ExtraInformationTable
        :info="model.info"
        @remove-extra-item="removeItemExtra($event)"
      />
    </div>

    <div class="form-group col-12 col-w-full col-lg-12 mb-6" align="center">
      <WhiteLabel>
        <button
          type="button"
          @click="validate()"
          class="btn btn-md btn-orange col-lg-4 col-md-5 col-sm-5 mt-1"
        >
          Continuar
        </button>
      </WhiteLabel>
    </div>
  </Fragment>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import { Fragment } from 'vue-fragment';
import Validator from '@/components/pix/transactions/bill/pix-forms/validator/DynamicPixValidator.js';
import ExtraInformationTable from '@/components/pix/transactions/bill/pix-forms/ExtraInformationTable.vue';
import ExtraInformationModal from '@/components/pix/transactions/bill/pix-forms/ExtraInformationModal.vue';
import SelectPixModal from '@/components/pix/transactions/bill/pix-forms/SelectPixModal.vue';
import Datepicker from 'vuejs-datepicker';
import ZipCodeApi from '@/services/ZipCodeApi';
import IbgeApi from '@/services/IbgeApi';

export default {
  name: 'DynamicPixForm',

  data: () => ({
    watchModel: false,
    showErrorKey: false,
    showErrors: false,
    isDisabledAddressLine1: false,
    isDisabledDistrict: false,
    isDisabledCity: false,
    isDisabledState: false,
    isDisabledCityCode: false,
    isRequired: false,
    isAddressRequired: false,
    email: '',
    amount: 0,
    state: {},
    city: {},
    listStates: [],
    listCities: [],
    modalPixKeys: 'modalPixKeys',
    modalExtraInformation: 'modalExtraInformation',
    model: {
      key: null,
      amount: 0,
      payer: {},
      dueDate: null,
      expiredInDays: '',
      interest: 0,
      fines: 0,
      discount: 0,
      rebate: 0,
      reusable: null,
      billingId: null,
      info: [],
      dueDateAfterDays: null,
    },
    payer: {
      taxDocument: '',
      name: '',
      email: '',
      address: {
        cityCode: '',
        district: '',
        line1: '',
        line2: '',
        streetNumber: '',
        zipCode: '',
      },
      note: '',
    },
  }),
  props: {
    hasExpirationDate: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    WhiteLabel,
    Fragment,
    ExtraInformationTable,
    ExtraInformationModal,
    Datepicker,
    SelectPixModal,
  },
  computed: {
    allCities() {
      return this.listCities;
    },
    allStates() {
      return this.listStates;
    },
    taxDocumentIsInvalid() {
      return (
        (this.isRequired &&
          !this.$v.payer.taxDocument.required &&
          this.$v.payer.taxDocument.$dirty) ||
        (this.isRequired &&
          this.showErrors &&
          !this.$v.payer.taxDocument.required &&
          !this.$v.payer.taxDocument.$dirty)
      );
    },
    emailIsInvalid() {
      return (
        (this.isRequired &&
          !this.$v.payer.email.required &&
          this.$v.payer.email.$dirty) ||
        (this.isRequired &&
          !this.$v.payer.email.required &&
          this.showErrors &&
          !this.$v.payer.email.$dirty)
      );
    },
    cepIsInvalid() {
      return (
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.payer.address.zipCode.required &&
          this.$v.payer.address.zipCode.$dirty) ||
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.payer.address.zipCode.required &&
          this.showErrors &&
          !this.$v.payer.address.zipCode.$dirty)
      );
    },
    line1IsInvalid() {
      return (
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.payer.address.line1.required &&
          this.$v.payer.address.line1.$dirty) ||
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.payer.address.line1.required &&
          this.showErrors &&
          !this.$v.payer.address.line1.$dirty)
      );
    },
    streetNumberIsInvalid() {
      return (
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.payer.address.streetNumber.required &&
          this.$v.payer.address.streetNumber.$dirty) ||
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.payer.address.streetNumber.required &&
          this.showErrors &&
          !this.$v.payer.address.streetNumber.$dirty)
      );
    },
    districtIsInvalid() {
      return (
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.payer.address.district.required &&
          this.$v.payer.address.district.$dirty) ||
        ((this.isRequired || this.isAddressRequired) &&
          this.showErrors &&
          !this.$v.payer.address.district.required &&
          !this.$v.payer.address.district.$dirty)
      );
    },
    stateIsInvalid() {
      return (
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.state.required &&
          this.$v.state.$dirty) ||
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.state.required &&
          this.showErrors &&
          !this.$v.state.$dirty)
      );
    },
    cityIsInvalid() {
      return (
        ((this.isRequired || this.isAddressRequired) &&
          !this.$v.payer.address.cityCode.required &&
          this.$v.payer.address.cityCode.$dirty) ||
        ((this.isRequired || this.isAddressRequired) &&
          this.showErrors &&
          !this.$v.payer.address.cityCode.required &&
          !this.$v.payer.address.cityCode.$dirty)
      );
    },
    expiredInDaysIsInvalid() {
      return (
        (!this.$v.model.expiredInDays.required &&
          this.$v.model.expiredInDays.$dirty) ||
        (this.showErrors && !this.$v.model.expiredInDays.$dirty)
      );
    },
  },

  async mounted() {
    this.isRequired = this.hasExpirationDate;
    if (this.isRequired) {
      const currentDate = Date.now();
      this.model.dueDate = new Date(currentDate);

      this.model.expiredInDays = null;
    }

    await this.searchStates();
  },
  validations: {
    ...Validator(),
  },
  watch: {
    'payer.address.zipCode'(value) {
      if (!value || value.length < 9) {
        this.clearFields();
      }
      if (value) {
        this.isAddressRequired = true;
      } else {
        if (
          !this.payer.address.cityCode &&
          !this.payer.address.streetNumber &&
          !this.payer.address.line1 &&
          !this.payer.address.district
        )
          this.isAddressRequired = false;
      }
    },
    'payer.address.line1'(value) {
      if (value) {
        this.isAddressRequired = true;
      } else {
        if (
          !this.payer.address.cityCode &&
          !this.payer.address.streetNumber &&
          !this.payer.address.zipCode &&
          !this.payer.address.district
        )
          this.isAddressRequired = false;
      }
    },
    'payer.address.streetNumber'(value) {
      if (value) {
        this.isAddressRequired = true;
      } else {
        if (
          !this.payer.address.cityCode &&
          !this.payer.address.line1 &&
          !this.payer.address.zipCode &&
          !this.payer.address.district
        )
          this.isAddressRequired = false;
      }
    },
    'payer.address.district'(value) {
      if (value) {
        this.isAddressRequired = true;
      } else {
        if (
          !this.payer.address.cityCode &&
          !this.payer.address.streetNumber &&
          !this.payer.address.zipCode &&
          !this.payer.address.line1
        )
          this.isAddressRequired = false;
      }
    },
    'payer.address.cityCode'(value) {
      if (value) {
        this.isAddressRequired = true;
      } else {
        if (
          !this.payer.address.line1 &&
          !this.payer.address.streetNumber &&
          !this.payer.address.zipCode &&
          !this.payer.address.district
        )
          this.isAddressRequired = false;
      }
    },
  },
  methods: {
    validatesMandatoryAddress() {
      this.isAddressRequired = true;
    },

    selectKey(key) {
      this.model.key = key.key;

      if (this.model.key) {
        this.showErrorKey = false;
      }
    },
    // if DuaDate is Checked, check if object payer.address different null
    // Onfocus in dueDate check duedate e change isRequired
    // check hasExpirationDate and show dueDate or Expiration

    addExtraInformation(info) {
      let id = Math.floor(Date.now() * Math.random()).toString(36);
      this.model.info.push({
        id: id,
        name: info.name,
        value: info.value,
      });
    },
    removeItemExtra(item) {
      let arrayNew = [];
      arrayNew = this.model.info.filter(function (i) {
        return i['id'] !== item.id;
      });
      this.model.info = [];
      this.model.info = arrayNew;
    },

    validate() {
      if (!this.model.key) {
        this.showErrorKey = true;
      }
      if (this.$v.amount.$invalid) {
        this.showErrors = true;
      }
      if (
        this.$v.payer.name.$invalid ||
        (this.$v.model.expiredInDays.$invalid && !this.isRequired) ||
        (this.$v.payer.address.$invalid &&
          this.isAddressRequired &&
          !this.isRequired)
      ) {
        this.showErrors = true;
      } else {
        this.showErrors = false;
      }

      if (this.isRequired) {
        if (this.$v.payer.$invalid || this.$v.model.dueDate.$invalid) {
          this.showErrors = true;
        } else {
          this.showErrors = false;
        }
      }

      if (this.showErrors || this.showErrorKey) {
        this.$alert.error('Ops', 'Existem campos inválidos!');
        return;
      }

      this.model.amount = this.amount;
      this.model.payer = this.payer;
      if (this.model.info.length > 0) {
        this.removeIdModelInfo(this.model.info);
      }

      this.removeNullData();

      this.nextStep();
    },
    nextStep() {
      this.$emit('next-step', this.model);
      this.watchModel = true;
    },
    clearFields() {
      if (
        this.payer.address.zipCode === '' ||
        this.payer.address.zipCode.length < 9
      ) {
        this.state = {};
        this.city = {};
        this.clearFieldsOrDisabled();
      }
    },
    removeIdModelInfo(arrayInfo) {
      for (let i = 0; i < arrayInfo.length; i++) {
        delete this.model.info[i].id;
      }
    },

    removeNullData() {
      this.removeOwnership(this.model);

      if (this.model.info.length === 0) {
        delete this.model.info;
      }

      this.removeOwnership(this.model.payer);
      this.removeOwnership(this.model.payer.address);

      if (this.checkEmptyObject(this.model.payer.address)) {
        delete this.model.payer.address;
      }
    },
    checkEmptyObject(object) {
      for (var prop in object) {
        if (object.hasOwnProperty(prop)) return false;
      }
      return true;
    },
    removeOwnership(object) {
      for (var item in object) {
        if (object[item] === null || !object[item]) {
          delete object[item];
        }
      }
    },
    async searchStates() {
      const api = new IbgeApi();
      this.listStates = (await api.searchStates()).sort(this.compareStates);
    },
    async searchZipCode(zipCode) {
      const zipCodeWithoutMask = zipCode.replace('-', '');
      if (zipCodeWithoutMask.length === 8) {
        const api = new ZipCodeApi();
        this.$bus.$emit('spinner-run');
        const result = await api.searchByZipCode(zipCodeWithoutMask);
        if (result.erro) {
          this.clearFieldsOrDisabled();
          this.$bus.$emit('spinner-stop');
          return;
        }
        this.payer.address.line1 = result.logradouro;
        this.payer.address.district = result.bairro;
        this.state = result.uf;
        this.city = result.localidade;
        this.payer.address.cityCode = result.ibge;

        this.isDisabledAddressLine1 = this.payer.address.line1 !== '';
        this.isDisabledDistrict = this.payer.address.district !== '';
        this.isDisabledCity = this.city !== '';
        this.isDisabledState = this.state !== '';
        this.isDisabledCityCode = this.payer.address.cityCode !== '';

        this.searchCities(this.state);
      }
      this.$bus.$emit('spinner-stop');
    },
    clearFieldsOrDisabled() {
      this.payer.address.line1 = '';
      this.payer.address.line2 = '';
      this.payer.address.district = '';
      this.state = '';
      this.city = '';
      this.payer.address.cityCode = '';
      this.payer.address.streetNumber = '';
      this.payer.address.cityCode = '';
      this.isDisabledAddressLine1 = false;
      this.isDisabledDistrict = false;
      this.isDisabledCity = false;
      this.isDisabledState = false;
      this.isDisabledCityCode = false;

      this.listCities = [];
    },
    async searchCities(initials) {
      if (initials !== '' && initials !== null && initials !== undefined) {
        this.listCities = [];
        const selectedState = this.listStates.filter(
          (state) => state.sigla === initials
        );
        const api = new IbgeApi();
        const result = await api.searchCities(selectedState[0].id);
        this.listCities = result;
        return;
      }
      this.listCities = [];
      this.payer.address.cityCode = null;
    },
    selectCity(cities) {
      for (var city in cities) {
        if (this.city === cities[city].nome) {
          this.isDisabledCityCode = true;
          this.payer.address.cityCode = cities[city].id;
        } else {
          this.isDisabledCityCode = false;
        }
      }
    },
  },
};
</script>

<style>
.redText {
  color: red;
}
</style>
