<template>
  <ModalBase
    :title="page.title"
    :hasFooter="false"
    size="transference-size"
    :id="id"
  >
    <div slot="content">
      <div class="form-group col-12 col-w-full p-2">
        <form>
          <WhiteLabel class="mt-4 mb-4 col-12 col-w-full">
            <button
              type="button"
              @click="openPixType('staticPix')"
              class="btn btn-md btn-orange col-12"
            >
              Código QR Simples
            </button>
            <div class="form-group col-12 container col-w-full text-muted">
              <small class="text-muted">
                Ideal para valores fixos e cobranças para valores indefinidos.
              </small>
            </div>
          </WhiteLabel>
          <WhiteLabel class="mt-4 mb-4 col-12 col-w-full">
            <button
              type="button"
              @click="openPixType('instantPix')"
              class="btn btn-md btn-orange col-12"
            >
              Código QR Imediato
            </button>
            <div class="form-group col-12 container col-w-full text-muted">
              <small class="text-muted">
                Use para cobranças rápidas, sem data de vencimento.
              </small>
            </div>
          </WhiteLabel>
          <WhiteLabel class="mt-4 mb-5 col-12 col-w-full">
            <button
              type="button"
              @click="openPixType('dueDatePix')"
              class="btn btn-md btn-orange col-12"
            >
              Código QR com Vencimento
            </button>
            <div class="form-group col-12 container col-w-full text-muted">
              <small class="text-muted">
                Crie cobranças com data de vencimento, juros, etc.
              </small>
            </div>
          </WhiteLabel>
        </form>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import ModalBase from '@/components/shared/ModalBase';

export default {
  name: 'SelectPixKeyQRCodeModalSection',

  components: {
    ModalBase,
    WhiteLabel,
  },

  data: () => ({
    page: {
      id: '',
      title: 'Realizar uma cobrança com PIX',
    },
  }),
  props: {
    id: {
      type: String,
      required: true,
    },
    balance: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    openPixType(pixType) {
      /* eslint-disable no-undef */
      $(`#${this.id}`).modal('toggle');
      //  If pixType is equal to 'instantPix' then 'hasExpirationDate' controls immediate pix
      //  with expiration or no
      let hasExpirationDate = true;

      this.$router.push({
        name: 'new-pix-charge',
        params: {
          balance: this.balance,
          pixType: pixType,
          hasExpirationDate:
            pixType === 'instantPix' ? !hasExpirationDate : hasExpirationDate,
        },
      });
    },
  },
};
</script>

<style>
</style>
