<template>
  <ModalBase
    :title="modalTitle"
    :hasFooter="false"
    :id="modalId"
    centered
    size="modal-md"
  >
    <div slot="content" style="width: 480px">
      <SetTransferAmountModalSection
        v-show="section === 'transfer'"
        :balance="balance"
        :maxAmount="originalAmount"
        :startingAmount="originalAmount"
        @set-amount="onSetAmount"
        ref="amountModal"
      ></SetTransferAmountModalSection>
      <AuthCodeModalSection
        v-show="section === 'authCode'"
        :ref="authCodeModalData.id"
        :subSection="authCodeModalData.subSection"
        :email="userPreset.holder.email"
        :mobile="userPreset.holder.mobile"
        :type="'pix-payment-order'"
        lastButtonText="Confirmar"
        @auth-sent="onAuthCodeSent"
        @code-ready="onCodeReady($event)"
      />
    </div>
  </ModalBase>
</template>

<script>
// components imports
import ModalBase from '@/components/shared/ModalBase';
import SetTransferAmountModalSection from '@/components/pix/transactions/transfer/SetTransferAmountModalSection.vue';
import AuthCodeModalSection from '@/components/shared/modal/AuthCodeModalSection';
import swal from 'sweetalert2';

// services imports
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi.js';
import AccountApi from '@/services/AccountApi';

// models imports
import { Presets } from '@/models/Presets';

// error imports
import PixOrderPaymentError from '@/errors/PixOrderPaymentError';

export default {
  name: 'AppPixRefundModal',
  components: {
    ModalBase,
    SetTransferAmountModalSection,
    AuthCodeModalSection,
  },
  async created() {
    await this.presets();
    await this.getBalanceAccount();
  },
  mounted() {
    $(`#${this.modalId}`).on('hidden.bs.modal', () => this.onHide());
  },
  data() {
    return {
      balance: null,
      section: 'transfer',
      modalTitle: 'Qual o valor que deseja devolver?',
      authCodeDestination: '',
      authCodeModalData: {
        id: 'authCodeModal',
        subSection: 'destination',
      },
      userPreset: new Presets(),
      refundAmount: 0,
    };
  },
  methods: {
    async presets() {
      const api = new AccountApi();
      this.userPreset = await api.presets();
    },
    async getBalanceAccount() {
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance();
    },
    onSetAmount(amount) {
      this.refundAmount = amount;
      this.modalTitle = 'Código de autorização';
      this.section = 'authCode';
    },
    onAuthCodeSent() {
      this.authCodeModalData.subSection = 'code';
    },
    async onCodeReady(authCode) {
      await this.refundOrder(authCode);
    },
    onHide() {
      this.amountSection();
      this.$refs.amountModal.amount = this.originalAmount;
      this.$refs[`${this.authCodeModalData.id}`].reset();
      this.authCodeModalData.subSection = 'destination';
    },
    amountSection() {
      this.modalTitle = 'Qual o valor que deseja devolver?';
      this.section = 'transfer';
    },
    async refundOrder(authCode) {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();
      const result = await api.pix.refundOrder(
        this.orderId,
        this.refundAmount,
        authCode
      );

      if (result.status == 200) {
        $(`#${this.modalId}`).modal('hide');
        this.$emit('refunded');
      }

      if (result.status == 422) {
        const errorMessage = new PixOrderPaymentError(result.data).getMessage();
        swal
          .fire({
            title: 'Ops',
            text: errorMessage,
            type: 'error',
          })
          .then(() => {
            $(`#${this.modalId}`).modal('hide');
          });
      }

      this.$bus.$emit('spinner-stop');
    },
  },
  props: {
    modalId: {
      type: String,
      default: '',
    },
    orderId: {
      type: String,
      default: '',
      required: true,
    },
    originalAmount: {
      type: Number,
      default: 0,
      required: true,
    },
  },
};
</script>
