<template>
  <ModalBase
    :title="page.title"
    :hasFooter="false"
    size="transference-size"
    :id="id"
  >
    <div slot="content">
      <RegisterDeviceModalSection
        v-if="page.id == sections.registerDevice.id"
        @register-device="registerDevice()"
        @unauthorized-device="unauthorizedDevice()"
      />
      <DoActiveDeviceModalSection
        v-if="page.id == sections.activeDevice.id"
        @active-device="activeDevice()"
        @dont-active-device="unauthorizedDevice()"
      />
      <SelectTransferDestinationModalSection
        v-if="page.id === sections.selectDestination.id"
        :destinations="destinations"
        @select-destination="selectDestination($event)"
      />
      <SelectBankAccountInstitution
        v-if="page.id === sections.selectPixInstitution.id"
        :icon="destinations.bankAccount.icon"
        @select-institution="selecInstitution($event)"
      />
      <InsertTransferDetailsModalSection
        v-if="page.id === sections.insertDetails.id"
        :destination="selectedDestination"
        :pixInstitution="institution"
        @fill-details="fillDetails($event)"
      />
      <SetTransferAmountModalSection
        v-if="page.id === sections.setAmount.id"
        :destination="selectedDestination"
        :balance="balance"
        @set-amount="setAmount($event)"
      />
      <SetTransferDescriptionModalSection
        v-if="page.id === sections.setDescription.id"
        @set-description="setDescription($event)"
      />
      <AuthCodeModalSection
        v-if="page.id === sections.authCode.id"
        :subSection="sections.authCode.subSection"
        :mobile="preset.holder.mobile"
        :email="preset.holder.email"
        :authCodeType="authCodeType"
        @auth-sent="authSent($event)"
        @code-ready="recieveAuthCode($event)"
      />
      <ConfirmTransferModalSection
        v-if="page.id === sections.confirm.id"
        :destination="selectedDestination"
        @confirm="sendTransfer($event)"
      />
    </div>
  </ModalBase>
</template>

<script>
// component imports
import ModalBase from '@/components/shared/ModalBase';
import SelectTransferDestinationModalSection from '@/components/pix/transactions/transfer/SelectTransferDestinationModalSection.vue';
import SelectBankAccountInstitution from '@/components/pix/transactions/transfer/SelectBankAccountInstitution.vue';
import InsertTransferDetailsModalSection from '@/components/pix/transactions/transfer/InsertTransferDetailsModalSection.vue';
import SetTransferAmountModalSection from '@/components/pix/transactions/transfer/SetTransferAmountModalSection.vue';
import SetTransferDescriptionModalSection from '@/components/pix/transactions/transfer/SetTransferDescriptionModalSection.vue';
import AuthCodeModalSection from '@/components/pix/transactions/transfer/AuthCodeModalSection.vue';
import ConfirmTransferModalSection from '@/components/pix/transactions/transfer/ConfirmTransferModalSection.vue';
import RegisterDeviceModalSection from '@/components/device/RegisterDeviceModalSection.vue';
import DoActiveDeviceModalSection from '@/components/device/DoActiveDeviceModalSection.vue';

import swal from 'sweetalert2';

// api imports
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import PaymentAccountsApiV2 from '@/services/v2/PaymentAccountsApiV2';
import AccountApi from '@/services/AccountApi';
import FingerPrintApi from '@/services/FingerPrintApi';
import DeviceApi from '@/services/DeviceApi';

// error imports
import PixKeyOwnerDetailsError from '@/errors/PixKeyOwnerDetailsError';
import PixOrderPaymentError from '@/errors/PixOrderPaymentError';
import DeviceApiError from '@/errors/DeviceApiError';

// model imports
import { Presets } from '@/models/Presets';

export default {
  name: 'CreateTransferModal',

  components: {
    ModalBase,
    SelectTransferDestinationModalSection,
    InsertTransferDetailsModalSection,
    SetTransferAmountModalSection,
    SetTransferDescriptionModalSection,
    ConfirmTransferModalSection,
    AuthCodeModalSection,
    SelectBankAccountInstitution,
    RegisterDeviceModalSection,
    DoActiveDeviceModalSection,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    balance: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    authCodeType: null,
    deviceId: null,
    requestId: null,
    action: '',
    page: {
      id: '',
      title: 'Gerar nova ordem de pagamento com PIX',
    },
    sections: {
      registerDevice: {
        title: 'Este dispositivo não está cadastrado',
        id: 'registerDevice',
      },
      activeDevice: {
        title: 'Deseja reativar este dispositivo?',
        id: 'activeDevice',
      },
      selectDestination: {
        title: 'Como deseja identificar o favorecido?',
        id: 'selectDestination',
      },
      selectPixInstitution: {
        title: 'Selecione a instituição de destino do pix',
        id: 'selectPixInstitution',
      },
      insertDetails: {
        title: 'Insira ',
        id: 'insertDetails',
      },
      setAmount: {
        title: 'Qual valor deseja enviar?',
        id: 'setAmount',
      },
      setDescription: {
        title: 'Deseja incluir uma descrição na transferência?',
        id: 'setDescription',
      },
      authCode: {
        title: 'Como deseja receber o código de autenticação?',
        subSection: 'destination',
        id: 'authCode',
      },
      confirm: {
        title: 'Confirmar dados',
        id: 'confirm',
      },
    },
    destinations: {
      taxDocument: {
        label: 'CPF / CNPJ',
        prefix: 'o',
        id: 'taxDocument',
        icon: 'fa fa-id-card-o',
      },
      mobile: {
        label: 'celular',
        prefix: 'o',
        id: 'mobile',
        icon: 'fa fa-phone',
      },
      email: {
        label: 'email',
        prefix: 'o',
        id: 'email',
        icon: 'fa fa-envelope',
      },
      aleatory: {
        label: 'chave aleatória',
        prefix: 'a',
        id: 'aleatory',
        icon: 'fa fa-key',
      },
      bankAccount: {
        label: 'conta bancária',
        prefix: 'a',
        id: 'bankAccount',
        icon: 'fa fa-university',
      },
    },
    selectedDestination: {
      label: '',
      prefix: '',
      id: '',
      icon: '',
      details: {
        account: {},
      },
      amount: null,
      description: null,
      authCode: '',
    },
    institution: {},
    preset: new Presets(),
  }),

  async mounted() {
    await this.loadPresets();
    await this.checkDeviceAuthorization();
    this.$bus.$on('cleanInput', async (result) => {
      await this.reset();
    });
  },

  methods: {
    async checkDeviceAuthorization() {
      let isNaturalPerson = this.preset.holder.company == null;

      if (isNaturalPerson) {
        const fingerPrintApi = new FingerPrintApi();
        await fingerPrintApi.verifyFingerPrintExpiration(this.preset.holder.id);
        const { fingerPrint } = JSON.parse(localStorage.getItem('fingerPrint'));
        const deviceApi = new DeviceApi();
        var result = await deviceApi.getDevice(fingerPrint.visitorId);
        if (result.status != 200) {
          this.setPage(this.sections.registerDevice);
        } else if (result.status == 200 && result.data.status == 'Inactive') {
          this.deviceId = result.data.id;
          this.requestId = fingerPrint.requestId;
          this.setPage(this.sections.activeDevice);
        } else {
          this.deviceId = result.id;
          this.requestId = fingerPrint.requestId;
          this.setPage(this.sections.selectDestination);
        }
      } else {
        this.setPage(this.sections.selectDestination);
      }
    },
    async reset() {
      await this.checkDeviceAuthorization();

      this.selectedDestination = {
        label: '',
        prefix: '',
        id: '',
        icon: '',
        details: {
          account: {},
        },
        amount: null,
        description: null,
      };
      this.sections.insertDetails.title = 'Insira ';

      this.sections.authCode.title =
        'Como deseja receber o código de autenticação?';
      this.sections.authCode.subSection = 'destination';
    },

    setPage(section) {
      this.page.id = section.id;
      this.page.title = section.title;
    },

    async loadPresets() {
      const api = new AccountApi();
      this.preset = await api.presets();
    },

    registerDevice() {
      this.action = 'register';
      this.authCodeType = 'device-control';
      this.setPage(this.sections.authCode);
    },

    unauthorizedDevice() {
      this.authCodeType = 'pix-payment-order';
      this.setPage(this.sections.selectDestination);
    },

    selectDestination(destination) {
      this.selectedDestination = destination;
      this.sections.insertDetails.title = `${this.sections.insertDetails.title} ${destination.prefix} ${destination.label} do favorecido`;

      if (destination.id == this.destinations.bankAccount.id)
        this.setPage(this.sections.selectPixInstitution);
      else this.setPage(this.sections.insertDetails);
    },

    selecInstitution(institution) {
      this.institution = institution;
      this.setPage(this.sections.insertDetails);
    },

    async fillDetails(details) {
      this.$bus.$emit('spinner-run');
      this.selectedDestination.details = details;

      if (this.selectedDestination.id !== this.destinations.bankAccount.id) {
        if (this.selectedDestination.id === this.destinations.taxDocument.id) {
          this.selectedDestination.details.value =
            this.selectedDestination.details.value
              .replace('.', '')
              .replace('.', '')
              .replace('-', '')
              .replace('/', '');
        } else if (
          this.selectedDestination.id === this.destinations.mobile.id
        ) {
          this.selectedDestination.details.value =
            this.selectedDestination.details.value.replace('+55 ', '');
        }
        const key = this.selectedDestination.details.value;
        const api = new PaymentAccountsApiV2();
        const result = await api.getPixKeyOwnerDetails(key);

        if (result.status == 200) {
          this.selectedDestination.details.account = {
            institutionName: result.data.account.institution,
            taxDocument: result.data.account.taxDocument,
            name: result.data.account.name,
          };
          this.setPage(this.sections.setAmount);
        } else if (result.status == 422) {
          const modelError = new PixKeyOwnerDetailsError({
            error: result.data.error,
          });
          this.$alert.error('Ops', modelError.getMessage());
        } else {
          this.$alert.error('Ops', 'Algo deu errado');
        }
      } else {
        this.selectedDestination.details.account = {
          institution: details.institutionNumber,
          institutionName: details.institutionName,
          branch: details.branchNumber,
          number: details.value,
          type: details.accountType,
          taxDocument: details.taxDocument,
          name: details.name,
        };
        this.setPage(this.sections.setAmount);
      }

      this.$bus.$emit('spinner-stop');
    },

    setAmount(amount) {
      this.selectedDestination.amount = amount;

      this.setPage(this.sections.setDescription);
    },

    setDescription(description) {
      this.selectedDestination.description = description;

      this.setPage(this.sections.authCode);
    },

    authSent(mobile) {
      this.sections.authCode.title = `Insira o código enviado para seu ${
        mobile ? 'celular' : 'email'
      }.`;

      this.sections.authCode.subSection = 'code';
      this.setPage(this.sections.authCode);
    },

    async createDevice(authCode) {
      const { fingerPrint } = JSON.parse(localStorage.getItem('fingerPrint'));
      const api = new DeviceApi();
      let result = await api.createDevice(
        fingerPrint.visitorId,
        fingerPrint.requestId,
        authCode
      );

      if (result.status == 200) {
        this.$alert.info('Dispositivo cadastrado!', '');
        swal({
          title: 'Dispositivo cadastrado!',
          text: '',
          type: 'success',
          allowOutsideClick: false,
        }).then(() => {
          this.authCodeType = 'pix-payment-order';
          this.deviceId = result.id;
          this.requestId = fingerPrint.requestId;
          this.setPage(this.sections.selectDestination);
        });
      } else if (result.status >= 400 || result.status <= 500) {
        this.$alert.error('Ops', result.data.title);
        const modelError = new DeviceApiError({
          error: result.data.title,
        });
        this.$alert.error('Ops', modelError.getMessage());
        this.reset();
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.reset();
      }

      this.$bus.$emit('spinner-stop');
    },

    activeDevice() {
      this.action = 'active';
      this.authCodeType = 'device-control';
      this.setPage(this.sections.authCode);
    },

    async recieveAuthCode(authCode) {
      if (this.authCodeType == 'device-control') {
        switch (this.action) {
          case 'register':
            await this.createDevice(authCode);
            break;
          case 'active':
            await this.activeDeviceAsync(authCode);
            break;
        }
        return;
      }

      this.selectedDestination.authCode = authCode;
      this.setPage(this.sections.confirm);
    },

    async activeDeviceAsync(authCode) {
      const api = new DeviceApi();
      let result = await api.activeDevice(this.deviceId, authCode);
      if (result.status == 204) {
        this.$alert.info('Dispositivo reativado!', '');
        swal({
          title: 'Dispositivo reativado!',
          text: '',
          type: 'success',
          allowOutsideClick: false,
        }).then(() => {
          this.sections.authCode.title =
            'Como deseja receber o código de autenticação?';
          this.setPage(this.sections.selectDestination);
        });
      } else if (result.status >= 400 || result.status <= 500) {
        const modelError = new DeviceApiError({
          error: result.data.title,
        });

        this.$alert.error('Ops', modelError.getMessage());
        this.reset();
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.reset();
      }

      this.$bus.$emit('spinner-stop');
    },

    async sendTransfer(event) {
      const api = new PaymentAccountsApi();
      this.$bus.$emit('spinner-run');

      let model = {
        amount: Number(this.selectedDestination.amount),
        description: this.selectedDestination.description,
        scheduledFor: event.schedule ? event.payDate : null,
      };

      if (this.selectedDestination.id !== this.destinations.bankAccount.id)
        model.key = this.selectedDestination.details.value;
      else {
        model.account = this.selectedDestination.details.account;
      }

      const result = await api.pix.createOrderPayment(
        model,
        this.selectedDestination.authCode,
        this.deviceId,
        this.requestId
      );
      if (result.status == 200) {
        if (result.data.confirmed) {
          this.$alert.info('Pronto', 'Ordem de pagamento enviada!');
          swal({
            title: 'Pronto',
            text: 'Ordem de pagamento enviada!',
            type: 'success',
            allowOutsideClick: false,
          }).then(() => {
            this.$emit('payment-created');
            this.reset();
          });
        } else if (!result.data.confirmed && result.data.errors === null) {
          this.$alert.info('Pronto', 'Ordem de pagamento agendada!');
          swal({
            title: 'Pronto',
            text: 'Ordem de pagamento agendada!',
            type: 'success',
            allowOutsideClick: false,
          }).then(() => {
            this.$emit('payment-created');
            this.reset();
          });
        } else if (result.data.errors) {
          this.$alert.error('Ops', result.data.errors[0].message);
        } else {
          this.$alert.error('Ops', 'Algo deu errado');
        }
      } else if (result.status == 422) {
        const modelError = new PixOrderPaymentError({
          error: result.data.error,
        });
        this.$alert.error('Ops', modelError.getMessage());
        this.reset();
      } else if (result.status == 400) {
        this.$alert.error('Ops', result.data.errors[0]);
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.reset();
      }

      this.$bus.$emit('spinner-stop');
    },
  },
};
</script>
