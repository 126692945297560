<template>
  <ModalBase
    title="Informações Extras"
    :hasFooter="false"
    size="transference-size"
    :id="id"
  >
    <div slot="content">
      <div class="form-group col-12 col-w-full p-2">
        <div class="form-group col-12 col-w-full col-lg-12 mb-4">
          <input
            type="text"
            placeholder="Nome do Campo"
            class="form-control col-12 col-md-10 col-sm-10 mb-10"
            v-model="info.name"
            :maxlength="100"
          />
          <small class="text-danger" v-if="!$v.info.name.required">
            O Nome do Campo é obrigatório.
          </small>
          <small class="text-danger" v-if="!$v.info.name.minLength">
            Mínimo 2 caracteres.
          </small>
        </div>

        <div class="form-group col-12 col-w-full col-lg-12 mb-4">
          <input
            type="text"
            placeholder="Valor do Campo"
            class="form-control col-12 col-md-10 col-sm-10 mb-10"
            v-model="info.value"
            :maxlength="150"
          />
          <small class="text-danger" v-if="!$v.info.value.required">
            O Valor do Campo é obrigatório.
          </small>
          <small class="text-danger" v-if="!$v.info.value.minLength">
            Mínimo 2 caracteres.
          </small>
        </div>
        <div>
          <WhiteLabel>
            <div class="form-group col-12 col-w-full col-lg-12 mb-12">
              <button
                type="button"
                @click="add()"
                class="
                  btn btn-md btn-orange
                  col-lg-4 col-md-5 col-sm-5
                  mt-1
                  mb-4
                "
              >
                Adicionar
              </button>
            </div>
          </WhiteLabel>
        </div>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import WhiteLabel from '@/components/shared/WhiteLabel';
import Validator from '@/components/pix/transactions/bill/pix-forms/validator/DynamicPixValidator.js';

export default {
  name: 'ExtraInformationModal',

  data: () => ({
    info: {
      name: '',
      value: '',
    },
  }),
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    ModalBase,
    WhiteLabel,
  },
  validations: {
    ...Validator(),
  },
  mounted() {},
  methods: {
    add() {
      if (this.$v.info.name.$invalid) {
        this.$alert.error('Ops', "Campo 'Nome do Campo' com pendência!");
        return;
      }
      if (this.$v.info.value.$invalid) {
        this.$alert.error('Ops', "Campo 'Valor do Campo' com pendência!");
        return;
      }

      this.$emit('add-extra-information', this.info);
      this.info.name = '';
      this.info.value = '';
      $(`#${this.id}`).modal('toggle');
    },
  },
};
</script>
