<template>
  <ModalBase
    title="Atendimento Pagcerto"
    :hasFooter="false"
    size="transference-size"
    :id="id"
  >
    <div slot="content">
      <div class="ted-container">
        <div class="d-flex form-group">
          <div class="form-group col-11">
            <p>
              De segunda a sexta, das 8 as 18h:
              <WhiteLabel>
                <a href="#" @click.prevent="copyToClipboard('#email')">
                  <i class="icon-small fa fa-copy gray" />
                  {{ email }}
                </a>
              </WhiteLabel>
              <input type="hidden" id="email" :value="email" />
            </p>
            <p>
              Demais horários, fins de semana e feriados:
              <WhiteLabel>
                <a href="#" @click.prevent="copyToClipboard('#phone')">
                  <i class="icon-small fa fa-copy gray" />
                  {{ phone }}
                </a>
                (Whatsapp)
              </WhiteLabel>
              <input type="hidden" id="phone" :value="phone" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </ModalBase>
</template>

<script>
// component imports
import ModalBase from '@/components/shared/ModalBase';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'CustomerServiceModal',

  components: {
    ModalBase,
    WhiteLabel,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    email: 'relacionamento@pagcerto.com.br',
    phone: '(79) 98128-2836',
  }),

  methods: {
    copyToClipboard(id) {
      let codeToCopy = document.querySelector(id);
      codeToCopy.setAttribute('type', 'text');
      codeToCopy.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        successful
          ? this.$alert.info('', 'Copiado para área de transferência!')
          : this.$alert.error(
            'Ops',
            'Não foi possível copiar para área de transferência'
          );
      } catch (err) {
        this.$alert.error(
          'Ops',
          'Não foi possível copiar para área de transferência'
        );
      }

      /* unselect the range */
      codeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style lang="scss" scoped>
.ted-container {
  padding: 1rem;
}
</style>
