<template>
  <div class="form-group col-12 col-w-full">
    <form action="#" @submit.prevent="setAmount()">
      <div class="form-group col-12 col-w-full">
        <money
          v-model="amount"
          class="input-value input-money"
          :decimal="','"
          :thousands="'.'"
          :precision="2"
          @input="$v.amount.$touch()"
        ></money>

        <small
          class="text-danger"
          v-if="!$v.amount.minValue && $v.amount.$dirty"
        >
          O valor deve ser maior que zero.
        </small>

        <small
          class="text-danger"
          v-if="!$v.amount.maxValue && $v.amount.$dirty"
          v-show="maxAmount"
        >
          O valor não deve ser maior que R${{ maxAmount }}.
        </small>
      </div>
      <div v-show="maxAmount" class="row my-3">
        <div class="col px-4">
          <small class="text-warning">
            Você pode devolver o valor total ou parte dele em até 90 dias após o
            recebimento.
          </small>
        </div>
      </div>
      <WhiteLabel v-if="allowSkip">
        <a
          href="#"
          class="ml-auto mr-4 col-12 col-w-full"
          @click.prevent="skip()"
        >
          Não especificar um valor
        </a>
      </WhiteLabel>
      <div v-if="getBalanceValue" class="d-flex form-group mt-4">
        <div class="form-group col-8">Disponivel para transferência</div>
        <div class="form-group col-4">
          <strong>R${{ getBalanceValue }}</strong>
        </div>
      </div>
      <div v-if="getPixFeeValue" class="d-flex form-group mt-4">
        <div class="form-group col-8">Taxa de transferência</div>
        <div class="form-group col-4">
          <strong>R${{ getPixFeeValue }}</strong>
        </div>
      </div>
      <WhiteLabel class="mt-4 mb-4 offset-8 col-4 col-w-full">
        <button type="submit" class="btn btn-md btn-orange col-12">
          Continuar
        </button>
      </WhiteLabel>
    </form>
  </div>
</template>

<script>
// component imports
import WhiteLabel from '@/components/shared/WhiteLabel';
import { Money } from 'v-money';

// validation imports
import { minValue, maxValue } from 'vuelidate/lib/validators';

export default {
  name: 'SetOrderAmountModalSection',

  components: {
    WhiteLabel,
    Money,
  },

  props: {
    balance: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    allowSkip: {
      type: Boolean,
      required: false,
      default: false,
    },
    startingAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    maxAmount: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data: () => ({
    amount: '',
  }),

  computed: {
    getBalanceValue() {
      if (!this.balance || !this.balance.currentBalance) return null;
      return (this.balance.currentBalance - this.balance.pixFee)
        .toFixed(2)
        .replace('.', ',');
    },
    getPixFeeValue() {
      if (!this.balance || !this.balance.pixFee) return null;
      return this.balance.pixFee.toFixed(2).replace('.', ',');
    },
  },

  methods: {
    setAmount() {
      if (this.$v.$invalid) {
        this.$alert.error('Ops', 'Quantia não é válida.');
        return;
      }

      this.$emit('set-amount', this.amount);
    },
    skip() {
      this.$emit('skip');
    },
  },

  validations() {
    const amount = {
      minValue: minValue(0.01),
    };
    if (this.maxAmount > 0) amount.maxValue = maxValue(this.maxAmount);

    return {
      amount,
    };
  },

  watch: {
    startingAmount: function (value) {
      if (this.startingAmount) {
        if (this.startingAmount > this.maxAmount) this.amount = this.maxAmount;
        else this.amount = this.startingAmount;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-money {
  font-size: 48px;
  max-width: 100%;
  border: none;
  outline: none;
  font-weight: lighter;
  border-bottom: solid thin;
  border-color: #a1a4b1;
}
</style>
