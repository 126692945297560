import axios from 'axios';

export default class PaymentsApi {
 async queryPartnerBankSlipLimits() {
  var result = await axios.get(
    `${
      process.env.VUE_APP_PAYMENTS_API_URL
    }/payment-limits/partner`,
  );

  return result;
  };

  async queryBankSlipLimits() {
  var result = await axios.get(
    `${
      process.env.VUE_APP_PAYMENTS_API_URL
    }/payment-limits`,
  );

  return result;
 };

 async updateCurrentLimit(model) {
  var result = await axios.put(
    `${
      process.env.VUE_APP_PAYMENTS_API_URL
    }/payment-limits`,
    model
  );

  return result;
 }
}
