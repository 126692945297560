<template>
  <div class="form-group col-12 col-w-full">
    <form action="#" @submit.prevent="registerDevice()">
      <div class="d-flow ml-2 mb-4">
          <h5 class="">
          Para melhorar a segurança da sua conta, só é possível transferir acima de R$ 200,00 por transação ou R$ 1000,00 por dia, através de dispositivos cadastrados.
          Deseja cadastrar este dispositivo?
          </h5>
      </div>
      <WhiteLabel class="mb-4 col-12">
        <button type="button" @click="dontRegisterDevice()"  class="btn btn-md btn-outline-orange col-12">
          Não cadastrar
        </button>
        <button type="submit" class="mt-3 btn btn-md btn-orange col-12">
          Cadastrar dispositivo
        </button>
        <small class="text-muted d-block mt-1">Um código de autenticação será enviado</small>
      </WhiteLabel>
    </form>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'RegisterDeviceModalSection',

  components: {
    WhiteLabel,
  },
  methods: {
    registerDevice() {
      this.$emit('register-device');
    },
    dontRegisterDevice() {
      this.$emit('unauthorized-device');
    },
  },
};
</script>
