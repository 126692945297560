<template>
  <div class="mb-5">
    <div class="col-12 col-w-full">
      <span class="font-weight-bold">{{ keys.count }} de {{ keys.limit }}</span>
      <div
        class="card card-action py-4 mt-3 mb-3"
        v-for="key in keys.keys"
        :key="key.key"
      >
        <div class="row m-0">
          <div
            class="col-12 col-sm-9 d-flex text-secondary justify-content-center justify-content-sm-start"
          >
            <div class="my-auto">
              <div class="d-flex mb-2">
                <span class="text-secondary text-md my-auto ml-2 ml-lg-3">
                  <i :class="`${keyDecoration[key.type].icon} fa-lg`"> </i>
                </span>
                <span class="text-secondary text-md my-auto ml-3 ml-lg-5">
                  {{ key.key }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3 d-flex">
            <div class="dropdown text-center mx-auto justify-content-end">
              <WhiteLabel>
                <button
                  type="button"
                  class="btn btn-outline-orange btn-pill dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Ações
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button
                      class="dropdown-item"
                      @click="deleteKey(key.type, key.key)"
                    >
                      Excluir
                    </button>
                  </li>
                </ul>
              </WhiteLabel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AuthCodeApi from '@/services/v2/AuthCodeApi';
import swal from 'sweetalert2';
import PixDeletionError from '@/errors/PixDeletionError';
import DeviceApi from '@/services/DeviceApi';
import FingerPrintApi from '@/services/FingerPrintApi';
import AccountApi from '@/services/AccountApi';
import { Presets } from '@/models/Presets';

export default {
  name: 'AppContentKeys',
  // eslint-disable-next-line vue/require-prop-types
  props: ['keys', 'contactInformation'],
  data() {
    return {
      authCode: null,
      authCodeRegex: new RegExp('^[0-9A-Za-z]+$'),
      deviceId: '',
      requestId: '',
      preset: new Presets(),
      keyDecoration: {
        cpf: {
          title: 'CPF',
          icon: 'fa fa-id-card-o',
        },
        cnpj: {
          title: 'CNPJ',
          icon: 'fa fa-id-card-o',
        },
        mobile: {
          title: 'Celular',
          icon: 'fa fa-phone',
        },
        email: {
          title: 'Email',
          icon: 'fa fa-envelope',
        },
        aleatory: {
          title: 'Chave aleatória',
          icon: 'fa fa-key',
        },
      },
    };
  },
  components: {
    WhiteLabel,
  },
  async mounted() {
    await this.getPreset();
    await this.checkDeviceAuthorization();
  },

  methods: {
    async getPreset() {
      var accountApi = new AccountApi();

      this.preset = await accountApi.presets();
    },

    async checkDeviceAuthorization() {
      this.isNaturalPerson = this.preset.holder.company == null;

      if (this.isNaturalPerson) {
        const fingerPrintApi = new FingerPrintApi();
        await fingerPrintApi.verifyFingerPrintExpiration(this.preset.holder.id);
        const { fingerPrint } = JSON.parse(localStorage.getItem('fingerPrint'));
        const deviceApi = new DeviceApi();
        const response = await deviceApi.getDevice(fingerPrint.visitorId);
        if (response.status == 200) {
          this.deviceId = response.data.id;
          this.requestId = fingerPrint.requestId;
        }
      }
    },

    async deleteKey(type, key) {
      var paymentAccountsApi = new PaymentAccountsApi();
      var paymentAccountAuthCodeApi = new AuthCodeApi();
      var authCodeModel = {};

      var mobile = this.contactInformation.mobile;
      var email = this.contactInformation.email;

      swal({
        title: 'Enviaremos um código para autenticação',
        input: 'select',
        inputOptions: {
          email: 'Email: ' + email,
          mobile: 'Celular : ' + mobile,
        },
        inputPlaceholder: 'Selecione por onde você deseja receber',
        showCancelButton: true,
        preConfirm: (value) => {
          if (value == '') {
            swal.showValidationError('Você deve selecionar uma opção!');
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();

          return;
        }

        this.$bus.$emit('spinner-run');

        if (res.value === 'mobile') {
          authCodeModel = {
            type: 'pix-delete-key',
            mobile: true,
          };
        } else {
          authCodeModel = {
            type: 'pix-delete-key',
            mobile: false,
          };
        }

        var authCodeResponse = await paymentAccountAuthCodeApi.getAuthCode(
          authCodeModel
        );

        if (authCodeResponse.status != 204) {
          this.$alert.error('Ops, erro durante envio de código');

          this.$bus.$emit('spinner-stop');

          return;
        }

        this.$bus.$emit('spinner-stop');

        swal({
          title: 'Deseja deletar sua chave PIX?',
          html: '<input type="text" maxlength="6" id="swal-input-authCode" class="form-control text-center" placeholder="Informe o código recebido">',
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          showCloseButton: true,
          preConfirm: (twoFactorAuthentication) => {
            this.authCode = document.getElementById(
              'swal-input-authCode'
            ).value;

            if (this.authCode == '') {
              swal.showValidationError('Campo obrigatório!');

              return;
            }

            var match = this.authCodeRegex.test(this.authCode);

            if (!match) {
              swal.showValidationError('Digite apenas números!');
            }
          },
          allowOutsideClick: () => !swal.isLoading(),
        }).then(async (res) => {
          if (
            res.dismiss &&
            (res.dismiss === 'close' ||
              res.dismiss === 'cancel' ||
              res.dismiss === 'overlay')
          ) {
            this.bankAccountEnabled = true;

            !swal.isLoading();
          } else {
            this.$bus.$emit('spinner-run');

            if (type == 'mobile') {
              key = key.replace('+55', '');
            }

            var response = await paymentAccountsApi.pix.deleteKey(
              type,
              key,
              this.authCode,
              this.deviceId,
              this.requestId
            );

            if (response.status == 204) {
              this.$alert.info('Pronto', 'Chave deletada!');

              this.$emit('updateKeys', key);

              this.$bus.$emit('spinner-stop');

              return;
            } else if (response.status == 422) {
              alert.title = 'Ops';
              const modelError = new PixDeletionError(response.data);
              this.$alert.error('Ops', modelError.getMessage());

              this.$bus.$emit('spinner-stop');
              return;
            }

            this.$alert.error('Ops', 'Ocorreu um erro inesperado');

            this.$bus.$emit('spinner-stop');
          }
        });
      });
    },
  },
};
</script>
