<template>
  <div class="container p-4">
    <div class="row mt-2">
      <div class="col-xl-4 col-lg-4 col-sm-10" style="height: 40px">
        <WhiteLabel>
          <div class="btn-group w-100" role="group">
            <button
              v-for="(data, index) in types"
              :key="index"
              type="button"
              @click="filters.owner = data.value"
              :class="[filters.owner === data.value ? 'btn active' : 'btn']"
            >
              {{ data.label }}
            </button>
          </div>
        </WhiteLabel>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="container">
          <div class="row mt-4">
            <div class="col">
              <h3>Pedidos em aberto</h3>
              <h4>Seus pedidos de portabilidades e reivindicações</h4>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <ListClaims
                ref="openList"
                @approved="loadLists(true)"
                @cancellationRequested="onCancellationRequest($event)"
              ></ListClaims>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col">
              <h3>Pedidos concluídos</h3>
              <h4>
                Seus pedidos de portabilidades e reivindicações encerrados nos
                últimos 90 dias
              </h4>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <ListClaims ref="completedList"></ListClaims>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ModalBase
        :id="authCodeModalData.id"
        :title="'Cancelamento de reivindicação'"
        :hasFooter="false"
        size="modal-md"
      >
        <div slot="content">
          <AuthCodeModalSection
            :ref="authCodeModalData.id"
            :subSection="authCodeModalData.subSection"
            :email="getEmailKey"
            :mobile="getMobileKey"
            :destination="this.selectedClaim.key"
            :disableEmail="this.selectedClaim.keyType === 'mobile'"
            :disableMobile="this.selectedClaim.keyType === 'email'"
            :type="'pix-register-key'"
            message="Enviaremos um código de verificação para validarmos a posse da chave. Este código expirará em 10 minutos"
            lastButtonText="Confirmar"
            @code-ready="cancelClaim($event)"
            @auth-sent="onAuthSent"
          />
        </div>
      </ModalBase>
    </div>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import ListClaims from '@/components/pix/claims/ListClaims';
import AuthCodeModalSection from '@/components/shared/modal/AuthCodeModalSection';
import ModalBase from '@/components/shared/ModalBase';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import swal from 'sweetalert2';
import AuthCodeError from '@/errors/AuthCodeError';

export default {
  components: {
    ListClaims,
    WhiteLabel,
    AuthCodeModalSection,
    ModalBase,
  },
  computed: {
    getEmailKey() {
      return this.selectedClaim.keyType === 'email'
        ? this.selectedClaim.key
        : null;
    },
    getMobileKey() {
      return this.selectedClaim.keyType === 'mobile'
        ? this.selectedClaim.key
        : null;
    },
  },
  data: () => ({
    types: [
      { value: true, label: 'Recebidas' },
      { value: false, label: 'Enviadas' },
    ],
    filters: {
      owner: null,
    },
    claims: [],
    authCodeModalData: {
      id: 'authCodeModal',
      subSection: 'destination',
    },
    selectedClaim: {
      id: null,
      key: null,
      keyType: null,
      isDonor: null,
    },
  }),

  async mounted() {
    $('#authCodeModal').on('hidden.bs.modal', () => {
      this.authCodeModalData.subSection = 'destination';
      this.$refs.authCodeModal.reset();
    });

    this.filters.owner = true;

    if (this.$route.params.isSent) {
      this.types[0].value = false;
      this.types[1].value = true;
    }
  },
  methods: {
    async cancelClaim(authCode) {
      var claimId = this.selectedClaim.id;
      if (claimId) {
        this.$bus.$emit('spinner-run');
        const api = new PaymentAccountsApi();
        const result = await api.pix.cancelClaim(claimId, authCode);
        this.$bus.$emit('spinner-stop');
        this.selectedClaim = {
          id: null,
          key: null,
          keyType: null,
        };

        $(`#${this.authCodeModalData.id}`).modal('hide');

        if (result.status == 200) {
          const response = result.data;
          if (!response.error) {
            swal.fire({
              title: 'Cancelada com sucesso!',
              type: 'success',
              timer: 2500,
            });
            await this.loadLists(this.filters.owner);
            return;
          }
        }

        let message = '';
        if (result.status == 422) {
          const authCodeError = new AuthCodeError(result.data);
          if (authCodeError) message = authCodeError.getMessage();
        }

        if (result.status == 200) {
          if (!result.data.confirmed && result.data.error !== null)
            message = result.data.error.message;
        }

        swal.fire({
          title: 'Falha ao tentar cancelar!',
          text: message,
          type: 'error',
        });
      }
    },
    async onCancellationRequest(payload) {
      this.selectedClaim = {
        id: payload.claimId,
        keyType: payload.keyType,
        key: payload.claimKey,
        isDonor: payload.isDonor,
      };

      if (
        payload.isDonor &&
        (payload.keyType === 'email' || payload.keyType === 'mobile')
      ) {
        $(`#${this.authCodeModalData.id}`).modal('show');
        return;
      }

      await this.cancelClaim();
    },
    onAuthSent() {
      this.authCodeModalData.subSection = 'code';
    },
    async loadLists(isReceivedList) {
      await this.$refs.openList.listClaims({
        owner: isReceivedList,
        status: 'pending',
      });

      var startDate = new Date();
      var daysAgo = startDate.getDate() - 90;

      startDate.setDate(daysAgo);

      await this.$refs.completedList.listClaims({
        owner: isReceivedList,
        resolved: true,
        startDate: startDate.toISOString(),
      });
    },
  },
  watch: {
    'filters.owner': {
      async handler(newValue) {
        await this.loadLists(newValue);
      },
      deep: true,
    },
  },
};
</script>
