import { Offset } from './Offset';

export class QrCodeFilter {
    typeDate = 'all'
    startDate = new Date()
    endDate = new Date()
    pixKey = null
    base64 = null
    type = 'all'
    offset = new Offset()
}

export class Payload{
    description = ''
    dueDate = null
    expiredAt = null
    createdAt = null
    interest = null
    fines = null
    discount = null
    payer = new Payer()
    info = []
}

export class Info {
    name = null
    value = null
}

export class Payer {
    payerTaxDocument = null
    payerName = null
    payerNote = null
}

export class QrCode {
    id = null
    type = null
    typeDate = null
    createdAt = null
    value = null
    base64 = null
    amount = null
    key = null
    status = null
    payload = new Payload()
}

export class QrCodeList {
    qrCodes = [];
    count = 0;
  }
