<template>
  <div class="row justify-content-center">
    <div class="col-12 col-w-full col-lg-10 col-xl-8">
      <div class="card mt-5">
        <div class="d-flex">
          <h3 class="font-weight-light pull-left ml-3">Código QR Simples</h3>
          <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
            <a href="#" @click="$router.go(-1)">
              <i class="icon-arrow-left-circle"></i> Voltar
            </a>
          </WhiteLabel>
        </div>
        <hr />
        <form>
          <div class="card-content mb-5">
            <div class="form-row">
              <div class="col-sm-12">
                <WhiteLabel>
                  <h4 class="font-weight-light blue">Dados do PIX</h4>
                </WhiteLabel>
              </div>

              <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                <label for="inputValor">Qual valor deseja receber?</label>
                <money
                  v-model="amount"
                  class="form-control col-lg-7 input-value input-money"
                  :decimal="','"
                  :thousands="'.'"
                  :precision="2"
                  @input="$v.amount.$touch()"
                  :disabled="isChecked"
                ></money>
                <small
                  class="text-danger"
                  v-if="!$v.amount.minValue && $v.amount.$dirty"
                >
                  O valor deve ser maior que zero.
                </small>
              </div>

              <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                <WhiteLabel class="d-flex col-12 pl-0">
                  <label class="radio-selector d-flex">
                    <input type="checkbox" v-model="isChecked" />
                    <div class="checkbox-badge mt-1 mr-2"></div>
                    <label class="mb-0 d-flex"> Não especificar um valor</label>
                  </label>
                </WhiteLabel>
              </div>

              <div
                class="
                  form-group
                  col-12 col-w-full col-md-6 col-sm-6 col-lg-5
                  mb-4
                "
              >
                <WhiteLabel class="">
                  <a
                    data-toggle="modal"
                    :data-target="'#' + modalPixKeys"
                    class="
                      pointer
                      btn btn-orange btn-pill
                      align-self
                      btn-transference
                      mb-3
                    "
                    >Minhas Chaves Pix *</a
                  >
                </WhiteLabel>
                <small
                  class="text-danger"
                  style="margin-left: 1.3rem"
                  v-if="showErrorKey"
                >
                  Chave pix é obrigatória</small
                >
              </div>
              <div
                class="
                  form-group
                  col-6 col-w-full col-md-6 col-lg-6 col-sm-6
                  mb-4
                "
              >
                <label>{{ model.key }}</label>
              </div>

              <div class="form-group col-12 col-w-full col-lg-12 mb-4">
                <label for="inputDescricao"
                  >Deseja incluir uma descrição na cobrança?</label
                >
                <textarea
                  v-model="model.description"
                  autofocus
                  class="form-control input-area col-12 col-w-full"
                  rows="4"
                  :maxlength="short ? 50 : 100"
                />
                <small
                  class="text-danger"
                  v-if="!$v.model.description.minLength"
                >
                  O mínimo dão 2 caracteres.
                </small>
              </div>

              <SelectPixModal
                :id="modalPixKeys"
                :balance="balance"
                @select-key="selectKey($event)"
              />
            </div>
            <hr />
            <div
              class="form-group col-12 col-w-full col-lg-12 mb-6"
              align="center"
            >
              <WhiteLabel>
                <button
                  type="button"
                  @click="validate()"
                  class="btn btn-md btn-orange col-lg-4 mt-1"
                >
                  Continuar
                </button>
              </WhiteLabel>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';
import { minValue, minLength } from 'vuelidate/lib/validators';
import SelectPixModal from '@/components/pix/transactions/bill/pix-forms/SelectPixModal.vue';

export default {
  name: 'StaticPix',

  data: () => ({
    isChecked: false,
    amount: '',
    model: {
      key: '',
      amount: '',
      description: '',
    },
    modalPixKeys: 'modalPixKeys',
    balance: {},
    showErrors: false,
    showErrorKey: false,
  }),
  props: {
    pixData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    WhiteLabel,
    SelectPixModal,
  },

  mounted() {
    this.balance = this.$route.params.balance;

    if (this.model.key !== '') {
      this.amount = this.pixData.amount;
      this.model.key = this.pixData.key;

      if (this.model.description) {
        this.model.description = this.pixData.description;
      }
    }
  },

  validations: {
    amount: {
      minValue: minValue(0.01),
    },
    model: {
      description: {
        minLength: minLength(2),
      },
    },
  },
  watch: {
    isChecked(value) {
      if (value === true) {
        this.amount = '';
      }
      if (value === false) {
        this.amount = 0;
      }
    },
  },
  methods: {
    getIcon(type) {
      switch (type) {
        case 'cnpj':
          return 'fa fa-id-card-o';

        case 'cpf':
          return 'fa fa-id-card-o';

        case 'mobile':
          return 'fa fa-phone';

        case 'email':
          return 'fa fa-envelope';

        case 'aleatory':
          return 'fa fa-key';

        default:
          break;
      }
    },

    // dontSetAmount() {
    //   this.model.amount = null;
    // },

    selectKey(key) {
      this.model.key = key.key;
      if (this.model.key) {
        this.showErrorKey = false;
      }
    },

    validate() {
      //  isChecked checks if you need value

      if (
        (this.$v.amount.$invalid && this.isChecked === false) ||
        !this.$v.model.description.minLength
      ) {
        this.showErrors = true;
      } else {
        this.showErrors = false;
      }
      if (!this.model.key) {
        this.showErrorKey = true;
      } else {
        this.showErrorKey = false;
      }

      if (this.showErrors || this.showErrorKey) {
        this.$alert.error('Ops', 'Existem campos inválidos!');
        return;
      }

      // if (this.$v.$invalid && this.isChecked === false) {
      //   this.$alert.error('Ops', 'Quantia não é válida.');
      //   return;
      // }

      this.model.amount = this.amount;

      // if (!this.model.key) {
      //   this.$alert.error('Ops', 'Selecione uma chave PIX válida.');
      //   return;
      // }

      // if (
      //   this.model.description !== null &&
      //   this.model.description.length === 1
      // ) {
      //   this.$alert.error('Ops', 'Descrição: Mínimo 2 caracteres.');
      //   return;
      // }

      if (!this.model.description) delete this.model.description;
      if (this.isChecked) delete this.model.amount;
      this.nextStep();
    },
    nextStep() {
      this.$emit('set-pix-data', this.model);
    },
  },
};
</script>
